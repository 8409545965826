import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './indexstyle.css';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import InputEmailsComponent from 'components/UI/InputEmailsComponent';
import { ContributionType } from 'helpers/constants';
import usePaidTier from 'hooks/usePaidTier';
import Select from 'components/FormUI/Select';
import copyContributionLinkToClipboard from 'utils/copyContributionLinkToClipboard';
import { Formik } from 'formik';
import Button from 'components/FormUI/Button';
import { getApplicationFormByContribId } from 'services/applicationForm.services';
import Loader from 'components/UI/Loader';
import * as S from './styled';
import { colors } from '../../../../../utils/styles';
import SendSvg from './SendIcon';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import CopySvg from './CopyIcon';

import { PAID_TIER_TITLES } from '../../../../../constants';
import { formatMoney } from 'utils/datesAndMoney';
import classes from './index.module.scss';
import { lightOrDark } from 'utils/utils';
import usePartnerCoach from 'hooks/usePartnerCoach';
import useAccount from 'hooks/useAccount';
function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

const FREE_BUTTON_TITLE = 'Copy Free Link';
const PAID_BUTTON_TITLE = 'Copy Paid Link';
const TOOLTIP_TITLE = 'Link Copied';

const StyledSelect = styled(Select)`
  width: 100%;
  // max-width: 190px;
  // margin-left: 20px !important;
  .MuiInputBase-root {
    margin-top: 0;
    .MuiSelect-root {
      color: ${({ color }) => color};
    }
    svg {
      path {
        fill: ${({ color }) => `${color} !important`};
      }
    }
  }
`;
const StyledInstuction = styled.p`
  margin: 0px 0 0 0;
  text-align: right;
  font-size: 90%;
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  color: ${colors.black};
`;

const ShareModalView = ({
  onCopyToClipboard,
  onPremiumCopyToClipboard,
  emails,
  onSetEmails,
  emailsError,
  setEmailsError,
  mobileView,
  tooltipOpen,
  setTooltipOpen,
  premiumTooltipOpen,
  setPremiumTooltipOpen,
  type,
  paymentInfo,
  isInvoiced,
  contributionId,
  setPriceId,
  applyTheming,
  symbol,
  handleSubmitEmails,
}) => {
  const dispatch = useDispatch();
  const { user } = useAccount();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const {
    themedColor,
    themedColorBtn,
    themedBackgroundColor,
    themedBackgroundColorBtn,
    themedCardBackgroundColor,
  } = getThemedColors(activeContribution);
  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });
  const formatPackagePrice = ({ packageCost, packageSessionDiscountPercentage }) => {
    return formatMoney((packageCost * (100 - packageSessionDiscountPercentage)) / 100, 2);
  };
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const activePaidTierCurrentPaymentPeriod = useShallowEqualSelector(
    paidTier.activePaidTierCurrentPaymentPeriodSelector,
  );
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isImpactPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.impact;
  const isYealryPayingImpactPlan =
    activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.impact &&
    activePaidTierCurrentPaymentPeriod === 'Annually';
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;
  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);

  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);

  const contributionType = activeContribution?.type;
  const [defaultPrice, setdefaultPrice] = useState(null);
  const [applicationTooltipOpen, setApplicationTooltipOpen] = useState(false);
  const [applicationPremiumTooltipOpen, setApplicationPremiumTooltipOpen] = useState(false);
  const colorToUse = determineColorToUse(activeContribution);
  const isLiveGroupOrMembership = useMemo(
    () =>
      type === ContributionType.contributionMembership ||
      type === ContributionType.contributionCourse ||
      type === ContributionType.contributionCommunity ||
      type === ContributionType.contributionOneToOne ||
      contributionType === ContributionType.contributionMembership ||
      contributionType === ContributionType.contributionCourse ||
      contributionType === ContributionType.contributionOneToOne ||
      contributionType === ContributionType.contributionCommunity,
    [contributionType, type],
  );
  const [showtag, setShowtag] = useState(false);
  const [hasApplicationForm, setHasApplicationForm] = useState(false);
  const [fetchingApplicationInfo, setFetchingApplicationInfo] = useState(false);
  const [showEasyBookingFreeTooltip, setShowEasyBookingFreeTooltip] = useState(false);
  const [showEasyBookingPaidTooltip, setShowEasyBookingPaidTooltip] = useState(false);

  useEffect(() => {
    setFetchingApplicationInfo(true);
    getApplicationFormByContribId(contributionId)
      .then(form => {
        setHasApplicationForm(Boolean(form));
      })
      .finally(() => {
        setFetchingApplicationInfo(false);
      });
  }, []);

  useEffect(() => {
    if (
      (type === ContributionType.contributionCourse || contributionType == ContributionType.contributionCourse) &&
      paymentInfo?.multiplePriceList.length > 0
    ) {
      const defaultValue = paymentInfo?.multiplePriceList.filter(item => item?.isDefault);
      setdefaultPrice(false);
      setAge(defaultValue[0]?.id);
      setPriceId(defaultValue[0]?.id);
    }
  }, []);
  const { partnerCoaches } = usePartnerCoach(contributionId);
  const isPartnerCoach = partnerCoaches.some(obj => obj.userId === user.id);
  // const { isScalePlan, isYealryPayingImpactPlan, isImpactPlan } = usePaidTier();

  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);
  const onClosePremiumTooltip = useCallback(() => setPremiumTooltipOpen(false), [setPremiumTooltipOpen]);
  useEffect(() => {
    setTimeout(onCloseTooltip, [2000]);
  }, [tooltipOpen]);

  const paymentOptions = paymentInfo?.paymentOptions;
  const [age, setAge] = React.useState(null);
  const [applyAge, setApplyAge] = React.useState(null);
  function hasUniquePropertyValues(array, property) {
    const propertyValues = array.map(item => item[property]);
    const uniqueValuesCount = new Set(propertyValues).size;
    return uniqueValuesCount === propertyValues.length;
  }

  const disablePaidButton = () => {
    if (paymentOptions.length === 1) {
      if (paymentOptions.includes('Free')) {
        return true;
      } else if (paymentOptions.includes('FreeSessionsPackage')) {
        return true;
      } else {
        return false;
      }
    } else if (paymentOptions.length === 2) {
      if (paymentOptions.includes('Free') && paymentOptions.includes('FreeSessionsPackage')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const dropDownData = useMemo(() => {
    if (
      type === ContributionType.contributionMembership ||
      contributionType === ContributionType.contributionMembership ||
      type === ContributionType.contributionCommunity ||
      contributionType === ContributionType.contributionCommunity
    ) {
      return activeContribution === null
        ? paymentInfo?.multiplePriceList.map(item => ({
            title: `${symbol}${formatMoney(item.cost)} ${
              item.paymentOption === 'MonthlyMembership' ? 'monthly membership price' : 'yearly membership price'
            }`,
            value: item.id,
          }))
        : activeContribution.paymentInfo?.multiplePriceList.map(item => ({
            title: `${symbol}${formatMoney(item.cost)} ${
              item.paymentOption === 'MonthlyMembership' ? 'monthly membership price' : 'yearly membership price'
            }`,
            value: item.id,
          }));
    } else if (
      type === ContributionType.contributionOneToOne ||
      contributionType === ContributionType.contributionOneToOne
    ) {
      return activeContribution === null
        ? paymentInfo?.multiplePriceList.map(data => ({
            title:
              paymentInfo?.paymentOptions.includes('PerSession') &&
              paymentInfo?.paymentOptions.includes('SessionsPackage')
                ? paymentInfo?.packageSessionDiscountPercentage > 0
                  ? `${symbol}${data?.cost} session price, ${symbol}${formatPackagePrice({
                      packageCost: data?.packageCost,
                      packageSessionDiscountPercentage: paymentInfo?.packageSessionDiscountPercentage,
                    })} package price`
                  : `${symbol}${data?.cost} session price, ${symbol}${data?.packageCost} package price`
                : paymentInfo?.paymentOptions.includes('PerSession') === false
                ? paymentInfo?.packageSessionDiscountPercentage > 0
                  ? `${symbol}${formatPackagePrice({
                      packageCost: data?.packageCost,
                      packageSessionDiscountPercentage: paymentInfo?.packageSessionDiscountPercentage,
                    })} package price`
                  : `${symbol}${data?.packageCost} package price`
                : `${symbol}${data?.cost} session price`,
            value: data.id,
          }))
        : paymentInfo?.multiplePriceList.map(data => ({
            title:
              activeContribution?.paymentInfo?.paymentOptions.includes('PerSession') &&
              activeContribution?.paymentInfo?.paymentOptions.includes('SessionsPackage')
                ? activeContribution?.paymentInfo?.packageSessionDiscountPercentage > 0
                  ? `${symbol}${data?.cost} session price, ${symbol}${formatPackagePrice({
                      packageCost: data?.packageCost,
                      packageSessionDiscountPercentage:
                        activeContribution?.paymentInfo?.packageSessionDiscountPercentage,
                    })} package price`
                  : `${symbol}${data?.cost} session price, ${symbol}${data?.packageCost} package price`
                : activeContribution?.paymentInfo?.paymentOptions.includes('PerSession') === false
                ? activeContribution?.paymentInfo?.packageSessionDiscountPercentage > 0
                  ? `${symbol}${formatPackagePrice({
                      packageCost: data?.packageCost,
                      packageSessionDiscountPercentage:
                        activeContribution?.paymentInfo?.packageSessionDiscountPercentage,
                    })} package price`
                  : `${symbol}${data?.packageCost} package price`
                : `${symbol}${data?.cost} session price`,
            value: data.id,
          }));
    } else {
      return activeContribution === null
        ? paymentInfo?.multiplePriceList.map(data => ({
            title:
              paymentInfo?.paymentOptions.includes('EntireCourse') &&
              paymentInfo?.paymentOptions.includes('SplitPayments')
                ? `${symbol}${data?.cost} full price, ${symbol}${data?.splittedCost} split price`
                : paymentInfo?.paymentOptions.includes('EntireCourse') === false
                ? `${symbol}${data?.splittedCost} split price`
                : `${symbol}${data?.cost} full price`,
            value: data.id,
          }))
        : paymentInfo?.multiplePriceList.map(data => ({
            title:
              activeContribution?.paymentInfo?.paymentOptions.includes('EntireCourse') &&
              activeContribution?.paymentInfo?.paymentOptions.includes('SplitPayments')
                ? `${symbol}${data?.cost} full price, ${symbol}${data?.splittedCost} split price`
                : activeContribution?.paymentInfo?.paymentOptions.includes('EntireCourse') === false
                ? `${symbol}${data?.splittedCost} split price`
                : `${symbol}${data?.cost} full price`,
            value: data.id,
          }));
    }
  }, [type, contributionType, activeContribution, paymentInfo, symbol]);

  useEffect(() => {
    if (dropDownData && dropDownData.length > 0) {
      if (!age) {
        if (
          type === ContributionType.contributionMembership ||
          contributionType === ContributionType.contributionMembership ||
          type === ContributionType.contributionCommunity ||
          contributionType === ContributionType.contributionCommunity
        ) {
          if (activeContribution === null) {
            if (hasUniquePropertyValues(paymentInfo?.multiplePriceList, 'paymentOption') === false) {
              setAge(dropDownData[0]?.value);
            } else {
              setAge(null);
            }
          } else {
            if (hasUniquePropertyValues(activeContribution.paymentInfo?.multiplePriceList, 'paymentOption') === false) {
              setAge(dropDownData[0]?.value);
            } else {
              setAge(null);
            }
          }
        } else {
          setAge(dropDownData[0]?.value);
        }
      }
      if (!applyAge) {
        if (
          type === ContributionType.contributionMembership ||
          contributionType === ContributionType.contributionMembership ||
          type === ContributionType.contributionCommunity ||
          contributionType === ContributionType.contributionCommunity
        ) {
          if (activeContribution === null) {
            if (hasUniquePropertyValues(paymentInfo?.multiplePriceList, 'paymentOption') === false) {
              setApplyAge(dropDownData[0]?.value);
            } else {
              setApplyAge(null);
            }
          } else {
            if (hasUniquePropertyValues(activeContribution.paymentInfo?.multiplePriceList, 'paymentOption') === false) {
              setApplyAge(dropDownData[0]?.value);
            } else {
              setApplyAge(null);
            }
          }
        } else {
          setApplyAge(dropDownData[0]?.value);
        }
      }
    }
  }, [dropDownData, age, applyAge]);

  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  return (
    <S.ShareModalMain style={applyTheming ? { color: themedColor } : {}} mobileView={mobileView}>
      <S.ColumnFlexContainer style={applyTheming ? { color: themedColor } : {}}>
        <div className="top-note">
          <p style={applyTheming ? { color: themedColor } : {}}>
            You can invite your clients to join your contributions by emailing them the signup page directly or, copying
            your landing page link to the clipboard and then pasting that link to invite your clients via email, text
            message, DM, social media & more!
          </p>
        </div>

        {hasApplicationForm ? (
          <div
            style={{
              borderLeft: applyTheming ? `4px solid ${colorToUse?.AccentColorCode}` : '4px solid #E9C64C',
              paddingLeft: '15px',
              marginBottom: '30px',
              // borderLeftColor: colorToUse.AccentColorCode,
            }}
          >
            <p className="sub-heading-share-popup">Application Page Links</p>
            <S.Title
              style={{
                color: applyTheming ? themedColor : null,
              }}
            >
              <div className="Invite-Purchase">
                <p style={{ color: themedColor }}>Invite Clients to Join and Pay</p>
              </div>
            </S.Title>
            <div
              className="invite-links-container"
              style={{
                justifyContent: 'space-between',
                marginTop: '15px',
                // maxWidth: '350px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tooltip
                  className="cursor-pointer"
                  title={TOOLTIP_TITLE}
                  open={applicationTooltipOpen}
                  onClose={() => setApplicationTooltipOpen(false)}
                  arrow
                >
                  <S.CopyToClipboardBlockDefault
                    className="share-btn"
                    style={{
                      color: disablePaidButton() ? '#9b9b9b' : textColor || 'white',
                      minWidth: '120px',
                    }}
                    onClick={event => {
                      if (disablePaidButton() === false) {
                        setApplicationTooltipOpen(true);
                        if (
                          type === ContributionType.contributionCourse ||
                          type === ContributionType.contributionOneToOne ||
                          contributionType === ContributionType.contributionCourse ||
                          contributionType === ContributionType.contributionOneToOne ||
                          type === ContributionType.contributionMembership ||
                          contributionType === ContributionType.contributionMembership ||
                          type === ContributionType.contributionCommunity ||
                          contributionType === ContributionType.contributionCommunity
                        ) {
                          copyContributionLinkToClipboard({ contributionId, code: applyAge, isApplication: true });
                        } else {
                          onCopyToClipboard(event, true);
                        }
                      }
                    }}
                  >
                    <div
                      style={
                        disablePaidButton()
                          ? {
                              // color: 'white',
                              // backgroundColor: colors.smokedGray,
                              backgroundColor: '#ededed',
                              color: '#9b9b9b',
                              fill: '#9b9b9b',
                              cursor: 'not-allowed',
                              border: 'none',
                              bottom: '-6px',
                              // position: 'relative',
                              width: '160px',
                              padding: '11px 16px',
                              borderRadius: '4px',
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: '900',
                              lineHeight: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }
                          : applyTheming
                          ? {
                              color: textColor || 'white',
                              backgroundColor: colorToUse?.PrimaryColorCode,
                              bottom: '-6px',
                              // position: 'relative',
                              width: '160px',
                              padding: '11px 16px',
                              borderRadius: '4px',
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: '900',
                              lineHeight: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }
                          : { bottom: '-6px', position: 'relative' }
                      }
                    >
                      {/* <S.ButtonIcon aria-label="close modal" component="span">
                        <S.ShareIcon color={applyTheming ? themedColor : 'black'} />
                      </S.ButtonIcon> */}
                      <CopySvg color={disablePaidButton() === true ? '#9b9b9b' : textColor || 'white'} />
                      {PAID_BUTTON_TITLE}
                    </div>
                  </S.CopyToClipboardBlockDefault>
                </Tooltip>
                {type === ContributionType.contributionCourse ||
                type === ContributionType.contributionOneToOne ||
                contributionType === ContributionType.contributionCourse ||
                contributionType === ContributionType.contributionOneToOne ? (
                  <Formik
                    initialValues={{
                      copyMySettingsFromContribution: age?.name,
                    }}
                  >
                    {paymentInfo?.multiplePriceList?.length >= 2 && (
                      <div style={{ width: mobileView ? '60%' : '55%' }}>
                        <StyledSelect
                          name="copyMySettingsFromContribution"
                          onChange={e => {
                            setdefaultPrice(true);
                            setAge(e.target.value);
                            setPriceId(e.target.value);
                          }}
                          onOpen={() => {
                            setShowtag(true);
                          }}
                          onClose={() => {
                            setShowtag(false);
                          }}
                          style={{ color: themedColor }}
                          itemTextColor={themedColor}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                color: themedColor,
                                backgroundColor: themedCardBackgroundColor,
                              },
                            },
                          }}
                          color={applyTheming ? themedColor : ''}
                          defaultValue={dropDownData[0]?.value}
                          items={dropDownData}
                        />
                      </div>
                    )}
                  </Formik>
                ) : (
                  (type === ContributionType.contributionMembership ||
                    contributionType === ContributionType.contributionMembership ||
                    type === ContributionType.contributionCommunity ||
                    contributionType === ContributionType.contributionCommunity) && (
                    <Formik
                      initialValues={{
                        copyMySettingsFromContribution: age?.name,
                      }}
                    >
                      {(paymentInfo?.multiplePriceList?.length > 2 ||
                        hasUniquePropertyValues(paymentInfo?.multiplePriceList, 'paymentOption') === false) && (
                        <div style={{ width: mobileView ? '60%' : '55%' }}>
                          <StyledSelect
                            name="copyMySettingsFromContribution"
                            onChange={e => {
                              setdefaultPrice(true);
                              setAge(e.target.value);
                              setPriceId(e.target.value);
                            }}
                            onOpen={() => {
                              setShowtag(true);
                            }}
                            onClose={() => {
                              setShowtag(false);
                            }}
                            style={{ color: themedColor }}
                            itemTextColor={themedColor}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  color: themedColor,
                                  backgroundColor: themedCardBackgroundColor,
                                },
                              },
                            }}
                            color={applyTheming ? themedColor : ''}
                            defaultValue={dropDownData[0]?.value}
                            items={dropDownData}
                          />
                        </div>
                      )}
                    </Formik>
                  )
                )}
              </div>
              {isLiveGroupOrMembership === false ? (
                (paymentInfo?.paymentOptions?.includes('Free') ||
                  paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')) && (
                  <>
                    {/* <div style={{ marginTop: '20px' }} className="Invite-Purchase"> */}
                    <p style={{ marginTop: '20px', color: themedColor }}>Invite Client to Join for Free</p>
                    {/* </div> */}
                    <S.LinkWrapp
                      hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                      onClick={event => {
                        setApplicationPremiumTooltipOpen(true);
                        onPremiumCopyToClipboard(event, true);
                      }}
                    >
                      <Tooltip
                        className="cursor-pointer"
                        title={TOOLTIP_TITLE}
                        open={applicationPremiumTooltipOpen}
                        onClose={() => setApplicationPremiumTooltipOpen(false)}
                        arrow
                      >
                        <S.CopyToClipboardBlock
                          className="share-btn"
                          hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                        >
                          <div
                            className={classes.copylink_btn}
                            style={
                              applyTheming
                                ? {
                                    color: textColor || 'white',
                                    backgroundColor: colorToUse?.PrimaryColorCode,
                                  }
                                : {}
                            }
                          >
                            {/* <S.ButtonIcon aria-label="close modal" component="span">
                              <S.ShareIcon
                                color={applyTheming ? themedColor : 'black'}
                                hasPremiumPlan={
                                  isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach
                                }
                              />
                            </S.ButtonIcon> */}
                            <CopySvg color={textColor || 'white'} />
                            {FREE_BUTTON_TITLE}
                          </div>
                        </S.CopyToClipboardBlock>
                      </Tooltip>
                    </S.LinkWrapp>
                  </>
                )
              ) : (
                <>
                  {!isInvoiced && (
                    <>
                      <div className="Invite-Purchase">
                        <p style={{ marginTop: '20px', color: themedColor }}>Invite Client to Join for Free</p>
                      </div>
                      <S.LinkWrapp
                        hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                        onClick={event => {
                          setApplicationPremiumTooltipOpen(true);
                          onPremiumCopyToClipboard(event, true);
                        }}
                      >
                        <Tooltip
                          className="cursor-pointer"
                          title={TOOLTIP_TITLE}
                          open={applicationPremiumTooltipOpen}
                          onClose={() => setApplicationPremiumTooltipOpen(false)}
                          arrow
                        >
                          <S.CopyToClipboardBlock
                            className="share-btn"
                            hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                          >
                            <div
                              className={classes.copylink_btn}
                              style={
                                applyTheming
                                  ? {
                                      color: textColor || 'white',
                                      backgroundColor: colorToUse?.PrimaryColorCode,
                                    }
                                  : {}
                              }
                            >
                              {/* <S.ButtonIcon aria-label="close modal" component="span">
                                <S.ShareIcon
                                  color={applyTheming ? themedColor : 'black'}
                                  hasPremiumPlan={
                                    isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach
                                  }
                                />
                              </S.ButtonIcon> */}
                              <CopySvg color={textColor || 'white'} />
                              {FREE_BUTTON_TITLE}{' '}
                            </div>
                          </S.CopyToClipboardBlock>
                        </Tooltip>
                      </S.LinkWrapp>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          fetchingApplicationInfo && <Loader />
        )}
        <div
          style={{
            borderLeft: applyTheming ? `4px solid ${colorToUse?.AccentColorCode}` : '4px solid #E9C64C',
            paddingLeft: '15px',
            marginBottom: '30px',
          }}
        >
          <p className="sub-heading-share-popup">Landing Page Links</p>
          <div
            className="invite-links-container"
            style={{
              justifyContent: 'space-between',
              marginTop: '15px',
              // maxWidth: '350px'
            }}
          >
            <S.Title
              style={{
                color: applyTheming ? themedColor : null,
              }}
            >
              <div className="Invite-Purchase">
                {isInvoiced ? (
                  <p style={{ color: themedColor }}> Invite Clients to Join and Pay via Invoice </p>
                ) : (
                  <p style={{ color: themedColor }}> Invite Clients to Join & Pay</p>
                )}
              </div>
            </S.Title>

            <div
              style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', width: '100%', flexWrap: 'wrap' }}
            >
              <S.LinkWrapp
                hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                onClick={() => {
                  if (disablePaidButton() === false) {
                    if (
                      type === ContributionType.contributionCourse ||
                      contributionType === ContributionType.contributionCourse
                    ) {
                      copyContributionLinkToClipboard({ contributionId, code: age });
                      setTooltipOpen(true);
                    } else if (
                      type === ContributionType.contributionOneToOne ||
                      contributionType === ContributionType.contributionOneToOne
                    ) {
                      copyContributionLinkToClipboard({ contributionId, code: age });
                      setTooltipOpen(true);
                    } else if (
                      type === ContributionType.contributionMembership ||
                      contributionType === ContributionType.contributionMembership
                    ) {
                      copyContributionLinkToClipboard({ contributionId, code: age });
                      setTooltipOpen(true);
                    } else if (
                      type === ContributionType.contributionCommunity ||
                      contributionType === ContributionType.contributionCommunity
                    ) {
                      copyContributionLinkToClipboard({ contributionId, code: age });
                      setTooltipOpen(true);
                    } else {
                      onCopyToClipboard();
                    }
                  }
                }}
                style={{ width: '40%' }}
              >
                <Tooltip
                  className="cursor-pointer"
                  title={TOOLTIP_TITLE}
                  open={tooltipOpen}
                  onClose={onCloseTooltip}
                  arrow
                >
                  <S.CopyToClipboardBlock
                    className="share-btn"
                    style={{
                      color: disablePaidButton() ? colors.smokedGray : null,
                      minWidth: '120px',
                    }}
                  >
                    <div
                      style={
                        disablePaidButton()
                          ? {
                              // color: 'white',
                              // backgroundColor: colors.smokedGray,
                              bottom: '-6px',
                              // position: 'relative',
                              backgroundColor: '#ededed',
                              color: '#9b9b9b',
                              fill: '#9b9b9b',
                              cursor: 'not-allowed',
                              border: 'none',
                              width: '160px',
                              padding: '11px 16px',
                              borderRadius: '4px',
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: '900',
                              lineHeight: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }
                          : applyTheming
                          ? {
                              color: textColor || 'white',
                              backgroundColor: colorToUse?.PrimaryColorCode,
                              bottom: '-6px',
                              // position: 'relative',
                              width: '160px',
                              padding: '11px 16px',
                              borderRadius: '4px',
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: '900',
                              lineHeight: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }
                          : { bottom: '-6px', position: 'relative' }
                      }
                    >
                      {/* <S.ButtonIcon aria-label="close modal" component="span">
                        <S.ShareIcon color={applyTheming ? themedColor : 'black'} />
                      </S.ButtonIcon> */}
                      <CopySvg color={textColor || 'white'} />
                      {PAID_BUTTON_TITLE}
                    </div>
                  </S.CopyToClipboardBlock>
                </Tooltip>
              </S.LinkWrapp>
              {type === ContributionType.contributionCourse ||
              type === ContributionType.contributionOneToOne ||
              contributionType === ContributionType.contributionCourse ||
              contributionType === ContributionType.contributionOneToOne ? (
                <Formik
                  initialValues={{
                    copyMySettingsFromContribution: age?.name,
                  }}
                >
                  {paymentInfo?.multiplePriceList?.length >= 2 && (
                    <div style={{ width: mobileView ? '60%' : '55%' }}>
                      <StyledSelect
                        name="copyMySettingsFromContribution"
                        onChange={e => {
                          setdefaultPrice(true);
                          setAge(e.target.value);
                          setPriceId(e.target.value);
                        }}
                        onOpen={() => {
                          setShowtag(true);
                        }}
                        onClose={() => {
                          setShowtag(false);
                        }}
                        style={{ color: themedColor }}
                        itemTextColor={themedColor}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              color: themedColor,
                              backgroundColor: themedCardBackgroundColor,
                            },
                          },
                        }}
                        color={applyTheming ? themedColor : ''}
                        defaultValue={dropDownData[0]?.value}
                        items={dropDownData}
                      />
                    </div>
                  )}
                </Formik>
              ) : (
                (type === ContributionType.contributionMembership ||
                  contributionType === ContributionType.contributionMembership ||
                  type === ContributionType.contributionCommunity ||
                  contributionType === ContributionType.contributionCommunity) && (
                  <Formik
                    initialValues={{
                      copyMySettingsFromContribution: age?.name,
                    }}
                  >
                    {(paymentInfo?.multiplePriceList?.length > 2 ||
                      hasUniquePropertyValues(paymentInfo?.multiplePriceList, 'paymentOption') === false) && (
                      <div style={{ width: mobileView ? '60%' : '55%' }}>
                        <StyledSelect
                          name="copyMySettingsFromContribution"
                          onChange={e => {
                            setdefaultPrice(true);
                            setAge(e.target.value);
                            setPriceId(e.target.value);
                          }}
                          onOpen={() => {
                            setShowtag(true);
                          }}
                          onClose={() => {
                            setShowtag(false);
                          }}
                          style={{ color: themedColor }}
                          itemTextColor={themedColor}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                color: themedColor,
                                backgroundColor: themedCardBackgroundColor,
                              },
                            },
                          }}
                          color={applyTheming ? themedColor : ''}
                          defaultValue={dropDownData[0]?.value}
                          items={dropDownData}
                        />
                      </div>
                    )}
                  </Formik>
                )
              )}
            </div>
          </div>
          {/* <div className="emailtext">
            <p>Email</p>
          </div>
          <S.SendEmailBlock style={{ color: applyTheming ? themedColor : undefined, maxWidth: '350px' }}>
            <InputEmailsComponent
              emails={emails}
              applyTheming
              disabled={disablePaidButton()}
              handleSetEmails={onSetEmails}
              emailsError={emailsError}
              setEmailsError={setEmailsError}
            />
            <Button
              style={{
                padding: '5px 22px',
                marginLeft: '12px',
                height: '36px',
                minWidth: '112px',
                backgroundColor: colorToUse?.PrimaryColorCode,
                color: colorToUse?.TextColorCode,
              }}
              onClick={handleSubmitEmails}
            >
              <SendSvg color={colorToUse?.TextColorCode} />
              <span>Send</span>
            </Button>
          </S.SendEmailBlock>
          <div style={{ display: 'flex', flex: '0.8' }}>
            <StyledInstuction className="Instruction-note">
              <p style={applyTheming ? { color: themedColor } : {}}>Separate emails with a comma.</p>
            </StyledInstuction>
          </div> */}
          <div
            className="invite-links-container"
            style={{
              justifyContent: 'space-between',
              marginTop: '15px',
              // maxWidth: '350px'
            }}
          >
            {isLiveGroupOrMembership === false ? (
              (paymentInfo?.paymentOptions?.includes('Free') ||
                paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')) && (
                <>
                  <div className="Invite-Purchase">
                    <p style={{ marginTop: '20px', color: themedColor }}>Invite Client to Join for Free</p>
                  </div>
                  <S.LinkWrapp
                    hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                    onClick={onPremiumCopyToClipboard}
                  >
                    <Tooltip
                      className="cursor-pointer"
                      title={TOOLTIP_TITLE}
                      open={premiumTooltipOpen}
                      onClose={onClosePremiumTooltip}
                      arrow
                    >
                      <S.CopyToClipboardBlock
                        className="share-btn"
                        hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                      >
                        <div
                          className={classes.copylink_btn}
                          style={
                            applyTheming
                              ? {
                                  color: textColor || 'white',
                                  backgroundColor: colorToUse?.PrimaryColorCode,
                                }
                              : {}
                          }
                        >
                          {/* <S.ButtonIcon aria-label="close modal" component="span">
                            <S.ShareIcon
                              color={applyTheming ? themedColor : 'black'}
                              hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                            />
                          </S.ButtonIcon> */}
                          <CopySvg color={textColor || 'white'} />
                          {FREE_BUTTON_TITLE}
                        </div>
                      </S.CopyToClipboardBlock>
                    </Tooltip>
                  </S.LinkWrapp>
                </>
              )
            ) : (
              <>
                {!isInvoiced && (
                  <>
                    <div className="Invite-Purchase">
                      <p style={{ marginTop: '20px', color: themedColor }}>Invite Client to Join for Free</p>
                    </div>
                    <S.LinkWrapp
                      hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                      onClick={onPremiumCopyToClipboard}
                    >
                      <Tooltip
                        className="cursor-pointer"
                        title={TOOLTIP_TITLE}
                        open={premiumTooltipOpen}
                        onClose={onClosePremiumTooltip}
                        arrow
                      >
                        <S.CopyToClipboardBlock
                          className="share-btn"
                          hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                        >
                          <div
                            className={classes.copylink_btn}
                            style={
                              applyTheming
                                ? {
                                    color: textColor || 'white',
                                    backgroundColor: colorToUse?.PrimaryColorCode,
                                  }
                                : {}
                            }
                          >
                            {/* <S.ButtonIcon aria-label="close modal" component="span">
                              <S.ShareIcon
                                color={applyTheming ? themedColor : 'black'}
                                hasPremiumPlan={
                                  isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach
                                }
                              />
                            </S.ButtonIcon> */}
                            <CopySvg color={textColor || 'white'} />
                            {FREE_BUTTON_TITLE}{' '}
                          </div>
                        </S.CopyToClipboardBlock>
                      </Tooltip>
                    </S.LinkWrapp>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {type === ContributionType.contributionOneToOne &&
          (paymentInfo?.paymentOptions?.includes('Free') || paymentInfo?.paymentOptions?.includes('PerSession')) && (
            <div
              style={{
                borderLeft: applyTheming ? `4px solid ${colorToUse?.AccentColorCode}` : '4px solid #4A4A4A',
                paddingLeft: '15px',
                marginBottom: '30px',
              }}
            >
              <p className="sub-heading-share-popup">Calendar Booking Page Links</p>
              <S.Title
                style={{
                  color: applyTheming ? themedColor : null,
                }}
              ></S.Title>
              <div
                className="invite-links-container"
                style={{
                  justifyContent: 'space-between',
                  marginTop: '15px',
                  // maxWidth: '350px'
                }}
              >
                <div className="Invite-Purchase">
                  <p style={{ color: themedColor }}>Invite Clients to Join and Pay</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '5px',
                    width: '100%',
                    flexWrap: 'wrap',
                  }}
                >
                  <S.LinkWrapp
                    hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                    onClick={() => {
                      if (disablePaidButton() === false) {
                        setShowEasyBookingPaidTooltip(true);
                        setTimeout(() => {
                          setShowEasyBookingPaidTooltip(false);
                        }, 2000);
                        copyContributionLinkToClipboard({ contributionId, code: applyAge, isEasyBooking: true });
                      }
                    }}
                    style={{
                      width: '40%',
                    }}
                  >
                    <Tooltip
                      className="cursor-pointer"
                      title={TOOLTIP_TITLE}
                      open={showEasyBookingPaidTooltip}
                      onClose={() => {
                        setShowEasyBookingPaidTooltip(false);
                      }}
                      arrow
                    >
                      <S.CopyToClipboardBlock
                        className="share-btn"
                        style={{
                          color: disablePaidButton() ? colors.smokedGray : null,
                          minWidth: '120px',
                        }}
                      >
                        <div
                          style={
                            disablePaidButton()
                              ? {
                                  // color: 'white',
                                  // backgroundColor: colors.smokedGray,
                                  bottom: '-6px',
                                  backgroundColor: '#ededed',
                                  color: '#9b9b9b',
                                  fill: '#9b9b9b',
                                  cursor: 'not-allowed',
                                  border: 'none',
                                  // position: 'relative',
                                  width: '160px',
                                  padding: '11px 16px',
                                  borderRadius: '4px',
                                  fontFamily: 'Avenir',
                                  fontSize: '14px',
                                  fontWeight: '900',
                                  lineHeight: '16px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                }
                              : applyTheming
                              ? {
                                  color: textColor || 'white',
                                  backgroundColor: colorToUse?.PrimaryColorCode,
                                  bottom: '-6px',
                                  // position: 'relative',
                                  width: '160px',
                                  padding: '11px 16px',
                                  borderRadius: '4px',
                                  fontFamily: 'Avenir',
                                  fontSize: '14px',
                                  fontWeight: '900',
                                  lineHeight: '16px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                }
                              : { bottom: '-6px', position: 'relative' }
                          }
                        >
                          {/* <S.ButtonIcon aria-label="close modal" component="span">
                            <S.ShareIcon color={applyTheming ? themedColor : 'black'} />
                          </S.ButtonIcon> */}
                          <CopySvg color={disablePaidButton() === true ? '#9b9b9b' : textColor || 'white'} />
                          {PAID_BUTTON_TITLE}
                        </div>
                      </S.CopyToClipboardBlock>
                    </Tooltip>
                  </S.LinkWrapp>
                  {type === ContributionType.contributionCourse ||
                  type === ContributionType.contributionOneToOne ||
                  contributionType === ContributionType.contributionCourse ||
                  contributionType === ContributionType.contributionOneToOne ? (
                    <Formik
                      initialValues={{
                        copyMySettingsFromContribution: age?.name,
                      }}
                    >
                      {paymentInfo?.multiplePriceList?.length >= 2 && (
                        <div style={{ width: mobileView ? '60%' : '55%' }}>
                          <StyledSelect
                            name="copyMySettingsFromContribution"
                            onChange={e => {
                              setdefaultPrice(true);
                              setAge(e.target.value);
                              setPriceId(e.target.value);
                            }}
                            onOpen={() => {
                              setShowtag(true);
                            }}
                            onClose={() => {
                              setShowtag(false);
                            }}
                            style={{ color: themedColor }}
                            itemTextColor={themedColor}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  color: themedColor,
                                  backgroundColor: themedCardBackgroundColor,
                                },
                              },
                            }}
                            color={applyTheming ? themedColor : ''}
                            defaultValue={dropDownData[0]?.value}
                            items={dropDownData}
                          />
                        </div>
                      )}
                    </Formik>
                  ) : (
                    (type === ContributionType.contributionMembership ||
                      contributionType === ContributionType.contributionMembership ||
                      type === ContributionType.contributionCommunity ||
                      contributionType === ContributionType.contributionCommunity) && (
                      <Formik
                        initialValues={{
                          copyMySettingsFromContribution: age?.name,
                        }}
                      >
                        {(paymentInfo?.multiplePriceList?.length > 2 ||
                          hasUniquePropertyValues(paymentInfo?.multiplePriceList, 'paymentOption') === false) && (
                          <div style={{ width: mobileView ? '60%' : '55%' }}>
                            <StyledSelect
                              name="copyMySettingsFromContribution"
                              onChange={e => {
                                setdefaultPrice(true);
                                setAge(e.target.value);
                                setPriceId(e.target.value);
                              }}
                              onOpen={() => {
                                setShowtag(true);
                              }}
                              onClose={() => {
                                setShowtag(false);
                              }}
                              style={{ color: themedColor }}
                              itemTextColor={themedColor}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    color: themedColor,
                                    backgroundColor: themedCardBackgroundColor,
                                  },
                                },
                              }}
                              color={applyTheming ? themedColor : ''}
                              defaultValue={dropDownData[0]?.value}
                              items={dropDownData}
                            />
                          </div>
                        )}
                      </Formik>
                    )
                  )}
                </div>
                {paymentInfo?.paymentOptions?.includes('Free') && (
                  <>
                    <div className="Invite-Purchase">
                      <p style={{ marginTop: '20px', color: themedColor }}>Invite Client to Join for Free</p>
                    </div>
                    <S.LinkWrapp
                      hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                      onClick={event => {
                        setShowEasyBookingFreeTooltip(true);
                        onPremiumCopyToClipboard(event, false, true);
                      }}
                    >
                      <Tooltip
                        className="cursor-pointer"
                        title={TOOLTIP_TITLE}
                        open={showEasyBookingFreeTooltip}
                        onClose={() => setShowEasyBookingFreeTooltip(false)}
                        arrow
                      >
                        <S.CopyToClipboardBlock
                          className="share-btn"
                          hasPremiumPlan={isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach}
                        >
                          <div
                            className={classes.copylink_btn}
                            style={
                              applyTheming
                                ? {
                                    color: textColor || 'white',
                                    backgroundColor: colorToUse?.PrimaryColorCode,
                                  }
                                : {}
                            }
                          >
                            {/* <S.ButtonIcon aria-label="close modal" component="span">
                              <S.ShareIcon
                                color={applyTheming ? themedColor : 'black'}
                                hasPremiumPlan={
                                  isScalePlan || isYealryPayingImpactPlan || isImpactPlan || isPartnerCoach
                                }
                              />
                            </S.ButtonIcon> */}
                            <CopySvg color={textColor || 'white'} />
                            {FREE_BUTTON_TITLE}
                          </div>
                        </S.CopyToClipboardBlock>
                      </Tooltip>
                    </S.LinkWrapp>
                  </>
                )}
              </div>
            </div>
          )}
      </S.ColumnFlexContainer>
    </S.ShareModalMain>
  );
};

ShareModalView.propTypes = {
  onCopyToClipboard: PropTypes.func.isRequired,
  onPremiumCopyToClipboard: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetEmails: PropTypes.func.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  setTooltipOpen: PropTypes.func.isRequired,
  setPremiumTooltipOpen: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
  tooltipOpen: PropTypes.bool,
  premiumTooltipOpen: PropTypes.bool,
  applyTheming: PropTypes.bool,
  handleSubmitEmails: PropTypes.func.isRequired,
};

ShareModalView.defaultProps = {
  mobileView: false,
  tooltipOpen: false,
  premiumTooltipOpen: false,
  applyTheming: false,
};

export default ShareModalView;
