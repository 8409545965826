import React, { useState, useEffect, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import ReferalRow from '../ReferalRow';
import { Pagination } from '@mui/material';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
    padding: 10,
  },
})(TableCell);

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const ReferalsTable = ({ affiliateData }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageNumber, setpageNumber] = useState(1);
  const [totalRecords, settotalrecords] = useState(affiliateData.length);
  const round = Math.ceil(totalRecords / 5);
  const itemsPerPage = 5;
  const indexOfLastItem = pageNumber * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = affiliateData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = pageNumber => setpageNumber(pageNumber);
  const handleChange = useCallback((event, value) => {
    if (value === 1) {
      setpageNumber(1);
    } else if (value > 1) {
      setpageNumber(value);
    } else {
      setpageNumber(value);
    }
    paginate(value);
  }, []);

  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Name</BoldTableCell>
              <BoldTableCell>Status</BoldTableCell>
              <BoldTableCell>Plan Purchased</BoldTableCell>
              <BoldTableCell>Frequency</BoldTableCell>
              <BoldTableCell>Revenue Earned</BoldTableCell>
              <BoldTableCell>Revenue Status</BoldTableCell>
              <BoldTableCell>Date Transferred</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((row, index) => (
              <ReferalRow key={index} index={index} row={row} />
            ))}
          </TableBody>
        </Table>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-12px' }}>
            {pageNumber === 0 ? 1 : pageNumber * 5 - 5 + 1} - {round != pageNumber ? pageNumber * 5 : totalRecords} of{' '}
            {totalRecords}
          </div>
          <Pagination
            count={round}
            page={pageNumber}
            onChange={handleChange}
            className={`client-info-table-pagination`}
            size="small"
            shape="rounded"
          />
        </div>
      </StyledTableContainer>
    </>
  );
};

export default ReferalsTable;
