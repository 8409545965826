import React, { Children, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { fetchTimeZones } from 'actions/timeZone';
import { useAccount, useClientPreview, useRouter, useScreenView } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { lighten } from 'polished';
import { colors } from 'utils/styles';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import Loader from 'components/UI/Loader';
import axiosInstance from 'utils/axiosInstance';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarContributionViewForPaymentCard.scss';
import { TIMEZONES } from 'constants.js';
import { UserRoles } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import { color } from '@mui/system';
import { Grid, SvgIcon, makeStyles, Select as Select2 } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import momenttz from 'moment-timezone';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors, getClientSlotsWithTimezone } from 'services/contributions.service';
import { Opacity } from '@mui/icons-material';
import { SelectStyled } from 'pages/CreateContribution/components/OneToOneForm';
import { fetchSlotsByTimezone } from 'actions/contributions';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';

const StyledHeading = styled.div`
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 25px;
  font-weight: 800;
  justify-content: center;
  display: flex;
`;

const PageSubtitleCustom = styled(PageSubtitle)`
  font-family: 'Avenir';
  font-style: normal;
  padding-top: 10px;
  // padding-bottom: 10px;
  margin-left: 10px;
`;

const localizer = momentLocalizer(moment);
const formats = {
  weekdayFormat: (date, culture, localizer1) => localizer1.format(date, 'dd', culture),
};
const allViews = [Views.MONTH];
const StyledTitle = styled.div`
  color: ${({ color }) => color || 'var(--Cohere-Primary-Blue, #215C73)'};
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;
const StyledTimezoneDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;
const StyledSelectTz = styled(Select2)`
  position: relative;
  border-radius: 0.375rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  .MuiSelect-select {
    padding: 0.63rem 0.95rem;
  }

  .MuiSelect-icon {
    color: ${({ iconColor }) => iconColor};
  }
`;

const CalendarContributionViewForPaymentCard = ({
  events,
  onSelectEvent,
  onDrillDown,
  calendarRef,
  rescheduleMode,
  dateToShow,
  loadingTimes,
  timeZoneId,
  contribution,
  selectedTimeZone,
  selectTimeZone,
}) => {
  const { mobileView } = useScreenView();
  const { currentRole } = useAccount();
  const { AccentColorCode = '' } = determineColorToUse(contribution.activeContribution);
  const isCoach = currentRole === UserRoles.cohealer;
  const modifiedevents = events.filter(e => moment(e.start).isAfter(moment().add(24, 'h')));
  const dispatch = useDispatch();
  const currentContribution = useContribution();
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const [colorForSelect, setColorForSelect] = useState('darkgrey');
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const { newThemedTextColor, themedBackgroundColor, newThemedBackgroundColor } = getThemedColors(currentContribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(currentContribution);
  const { clientPreviewMode } = useClientPreview();
  const { pathname } = useRouter();
  const {
    id: contributionId,
    durations = [],
    sessionIncrements = [],
    title,
    serviceProviderName,
  } = currentContribution;
  const duration = durations && durations.length ? durations[0] : 60;
  const offset = sessionIncrements && sessionIncrements.length ? sessionIncrements?.[0] : 0;
  const isSessionPage = window?.location?.pathname?.includes('/sessions');
  const easyBookingData = useSelector(state => state?.easyBooking);

  const eventsFil = useSelector(s => {
    const contributionTimes = s.contributions.contributionTimes || [];
    return getTimePeriodsForAvailability({
      availabilityPeriods: contributionTimes,
      duration,
      title,
      serviceProviderName,
    }).map(e => {
      const { isBooked } = e;
      if (!isBooked) {
        return e;
      }
      const bookedTime = contributionTimes.find(at => at.id === e.id).bookedTimes.find(bt => bt.id === e.bookedTimeId);

      return {
        ...e,
        participantId: bookedTime.participantId,
        videoRoomInfo: bookedTime.videoRoomInfo,
      };
    });
  });
  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
  }, [timeZones, timeZoneLoading]);
  const useSelectStyle = makeStyles(() => ({
    select: {
      width: '100%',
      color: colorForSelect,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #f5f0f0',
    },
  }));
  const selectStyle = useSelectStyle();
  const colorToUse = determineColorToUse(contribution.activeContribution);
  const [loading, setLoading] = useState(false);

  // const getTimezoneBasedTimes = () => {
  //   setLoading(true);
  //   dispatch(
  //     fetchSlotsByTimezone(
  //       contributionId,
  //       offset,
  //       selectedTimeZone,
  //       moment().startOf('month').format('YYYY-MM-DD'),
  //       moment().add(duration, 'days').format('YYYY-MM-DD'),
  //       duration,
  //     ),
  //   ).then(() => {
  //     setLoading(false);
  //   });
  // };
  // useEffect(() => getTimezoneBasedTimes(), [selectedTimeZone]);

  const CustomToolbar = toolbar => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };

    const label = () => {
      const date = moment(toolbar.date);
      const todayDate = moment(new Date());
      return (
        <span>
          {/* <b>{date.format('DD')}</b> */}
          <b> {date.format('MMMM')}</b>
          <span>
            {' '}
            <b>{date.format('YYYY')}</b>
          </span>
        </span>
      );
    };

    return (
      <div className="toolbar-container">
        <div>
          <button
            type="button"
            className="btn-back"
            style={{
              backgroundColor: isDarkThemeEnabled ? '#252728' : 'white',
              color: newThemedTextColor,
              fontSize: '25px',
            }}
            onClick={goToBack}
          >
            {`<`}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn-current"
            style={{ backgroundColor: isDarkThemeEnabled ? '#252728' : 'white', color: newThemedTextColor }}
            onClick={goToCurrent}
          >
            {label()}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn-next"
            style={{
              backgroundColor: isDarkThemeEnabled ? '#252728' : 'white',
              color: newThemedTextColor,
              fontSize: '25px',
            }}
            onClick={goToNext}
          >
            {`>`}
          </button>
        </div>
      </div>
    );
  };

  const CustomToolbarCalendly = toolbar => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span style={{ fontFamily: 'Avenir', fontSize: '20px', fontStyle: 'normal', fontWeight: '400' }}>
          {date.format('MMMM')}
          <span> {date.format('YYYY')}</span>
        </span>
      );
    };

    return (
      <div className="toolbar-container calendly">
        <div>
          <button
            type="button"
            className="btn-current"
            style={{ backgroundColor: isDarkThemeEnabled ? '#252728' : 'white', color: newThemedTextColor }}
            onClick={goToCurrent}
          >
            {label()}
          </button>
        </div>
        <div className="navigation-buttons">
          <div>
            <SvgIcon onClick={goToBack} style={{ width: '29px', height: '29px' }} viewBox="0 0 29 29">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <path
                  d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                  fill={newThemedTextColor}
                />
              </svg>
            </SvgIcon>
          </div>
          <div>
            <SvgIcon onClick={goToNext} style={{ width: '29px', height: '29px' }} viewBox="0 0 29 29">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <path
                  d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                  fill={newThemedTextColor}
                />
              </svg>
            </SvgIcon>
          </div>
        </div>
      </div>
    );
  };

  const ColoredDateCellWrapper = ({ children, value }) => {
    const today = new Date();
    const momentToday = moment(today).format('MMM Do YY');
    const momentValue = moment(value).format('MMM Do YY');

    const eventDates = [];
    const bookedDates = [];
    for (var e of events) {
      const d = moment(e.start).format('MMM Do YY');
      if (!eventDates.includes(d)) {
        eventDates.push(d);
      }
      if (e.isBooked) {
        if (!bookedDates.includes(d)) {
          bookedDates.push(d);
        }
      }
    }
    let bgColor = themedBackgroundColor;
    let opacity = '100%';
    if (eventDates.includes(momentValue)) {
      bgColor = AccentColorCode;
      opacity = '30%';
    }
    if (momentValue === momentToday) {
      bgColor = AccentColorCode;
      opacity = '100%';
    }
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        border: 'none',
        borderRadius: '25px',
        maxWidth: '33px',
        maxHeight: '33px',
        backgroundColor: bgColor,
        fontWeight: '900',
        opacity,
      },
    });
  };

  return (
    <div
      className="calendar-container-custom"
      style={{ color: newThemedTextColor, padding: mobileView ? '16px 6px 16px 6px' : '0px 16px 16px 16px' }}
      ref={calendarRef}
    >
      <Grid item md={12} xs={12} className="book-your-next-session" style={{ padding: '10px 0px' }}>
        <StyledHeading>{rescheduleMode ? 'Reschedule your session' : 'Book your next session'}</StyledHeading>
        <PageSubtitleCustom style={{ color: newThemedTextColor }}>
          Session Availability in {TIMEZONES[selectedTimeZone || currentContribution?.timeZoneId || timeZoneId]}.{' '}
          {isCoach && !clientPreviewMode
            ? 'These are the session times that can be booked.'
            : 'Choose a time on the calendar below that works best for you.'}
        </PageSubtitleCustom>
      </Grid>
      {loadingTimes && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div className="rbc-month-view">
            <div className="">
              <Loader relative />
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div className="rbc-month-view">
            <div className="">
              <Loader />
            </div>
          </div>
        </div>
      )}
      {!loadingTimes && (
        <div className={`calendar-container-custom ${isDarkThemeEnabled ? 'cohere-dark-mode' : ''}`}>
          <p
            className={
              window?.location?.pathname?.includes('/sessions') && !contribution?.activeContribution?.isPurchased
                ? 'easy-booking-title'
                : 'non-easy-booking-title'
            }
            style={{ color: AccentColorCode }}
          >
            Select Date and Time
          </p>
          <StyledTimezoneDropdownContainer>
            <StyledTitle {...{ color: newThemedTextColor, textAlign: 'center' }}>
              {moment(easyBookingData?.selectedDate).format('MMMM DD YYYY')}
            </StyledTitle>
            <StyledSelectTz
              required
              native
              iconColor={isDarkThemeEnabled ? newThemedTextColor : ''}
              onChange={e => {
                selectTimeZone(e.target.value);
                const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                setColorForSelect(color);
              }}
              disableUnderline
              style={
                !isSessionPage
                  ? {
                      backgroundColor: newThemedBackgroundColor,
                      color: newThemedTextColor,
                      width: '70%',
                      alignSelf: 'center',
                      borderRadius: '1.375rem',
                    }
                  : {
                      backgroundColor: newThemedBackgroundColor,
                      color: newThemedTextColor,
                    }
              }
              value={selectedTimeZone || currentContribution?.timeZoneId || timeZoneId}
            >
              <option
                aria-label="Time Zone"
                value={null}
                style={{ backgroundColor: newThemedBackgroundColor, color, newThemedTextColor }}
              >
                Select Timezone
              </option>
              {timeZones?.length > 0 &&
                timeZones.map(timeZone => (
                  <option
                    style={{ backgroundColor: newThemedBackgroundColor, color, newThemedTextColor }}
                    value={timeZone?.countryName}
                  >
                    {timeZone?.name}
                  </option>
                ))}
            </StyledSelectTz>
          </StyledTimezoneDropdownContainer>
          <BigCalendar
            className={`custom-calendar ${isDarkThemeEnabled ? 'dark-theme' : ''}`}
            style={{ color: 'black' }}
            localizer={localizer}
            // events={rescheduleMode ? modifiedevents : events}
            events={[]}
            views={allViews}
            defaultView={Views.MONTH}
            // date={dateToShow}
            formats={formats}
            components={{
              toolbar: pathname.includes('sessions') ? CustomToolbarCalendly : CustomToolbar,
              dateCellWrapper: ColoredDateCellWrapper,
            }}
            step={30}
            showMultiDayTimes
            titleAccessor={event => `${event.title.substring(0, 15)}...`}
            defaultDate={new Date()}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={onSelectEvent}
            onDrillDown={onDrillDown}
          />
        </div>
      )}
    </div>
  );
};

CalendarContributionViewForPaymentCard.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  loadingTimes: PropTypes.bool,
  timeZoneId: PropTypes.string,
};

CalendarContributionViewForPaymentCard.defaultProps = {
  calendarRef: null,
  loadingTimes: false,
};

const mapStateToProps = ({ contributions }) => ({
  loadingTimes: contributions?.loadingTimes,
  contribution: contributions,
});

export default connect(mapStateToProps, null)(CalendarContributionViewForPaymentCard);
