import React, { useState } from 'react';
import classes from './managebanksettings.module.scss';
import Modal from 'components/UI/Modal';
import Button from 'components/FormUI/Button';
import PayoutsTable from '../../CohereReferalsDetailsPage/Components/CohereAffiliatePayouts/Components/PayoutsTableContainer';
import BankAccountCard from 'pages/Account/Payment/components/BankAccountCard/BankAccountCard';
import AddBankAccountPopup from 'pages/Account/Payment/components/BankAccountCard/AddBankAccountPopup';
import * as userActions from 'actions/user';
import useAccount from 'hooks/useAccount';
import { useDispatch } from 'react-redux';

export default function ManageBankSettings({ setShowBankSettings, showBankSettings }) {
  const [addStripeBankAccountDataPopup, setAddStripeBankAccountDataPopup] = useState(false);
  const { user } = useAccount();
  const dispatch = useDispatch();
  return (
    <Modal
      disableFooter={true}
      isOpen={showBankSettings}
      title="Connected Banks For Affiliate Revenue Payout"
      closeClick={() => {
        setShowBankSettings(false);
      }}
      onCloseClick={() => {
        setShowBankSettings(false);
      }}
      onSubmit={() => {
        setShowBankSettings(false);
      }}
    >
      <div className={classes.container}>
        <div className={classes.button_container}>
          <Button
            onClick={() => {
              setAddStripeBankAccountDataPopup(true);
            }}
            className={classes.invite_button}
          >
            Manual Connect
          </Button>
        </div>
        <div className={classes.tablecontainer}>
          <BankAccountCard type={'affiliatetab'} />
        </div>
      </div>
      <AddBankAccountPopup
        addStripeBankAccountDataPopup={addStripeBankAccountDataPopup}
        type={'affiliate'}
        onCancel={async () => {
          setAddStripeBankAccountDataPopup(false);
          userActions.getProfile(user.id, dispatch);
        }}
      />
    </Modal>
  );
}
