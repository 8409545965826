import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import { fetchTimeZones } from 'actions/timeZone';
import * as countryActions from 'actions/country';
import isEmpty from 'lodash/isEmpty';
import { RESTORE_PASS } from './PurchaseModal.constants';
import { useIsEmailExist } from 'utils/useIsEmailExist';
import { getPurchaseValidationSchema } from 'utils/validation';
import { useAccount, useHttp, useRouter } from 'hooks';
import { LabelText } from 'components/UI/Text/TextStyles';
import { If, mapTo } from 'utils/fp';
import { login, completeRegister, LOG_IN_FAILURE, REGISTER_FAILURE, CONTINUE_REGISTER_FAILURE } from 'actions/user';
import * as purchaseService from 'services/purchase.service';
import { redirectTo } from 'services/links';
import Modal from 'components/UI/Modal';
import { editUserProfile } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { ContributionPurchaseType, ContributionType, PaymentStatus, UserRoles } from 'helpers/constants';
import {
  fetchClientContribution,
  FETCH_CONTRIBUTION_SUCCESS,
  fetchContributionActions,
  setCouponCode,
} from 'actions/contributions';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import {
  determineDarkThemedColorToUse,
  getSingleContract,
  joinContribution,
  saveSignoffData,
  setSignedContract,
} from 'services/contributions.service';
import isNil from 'lodash/isNil';
import useContribution from '../../hooks/useContribution';
import {
  PURCHASE_MODAL_STEPS,
  PURCHASE_MODAL_TITLES,
  ACCOUNT_FORM_FIELDS,
  DISCOUNT_CODE_FIELDS,
} from './PurchaseModal.constants';
import { PurchaseModalForm } from './PurchaseModalForm';
import { PAYMENT_OPTIONS, ROUTES } from '../../../../constants';
import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import { FailedPurchaseModal } from 'components/Modals/FailedPurchaseModal';
import { PAYMENTS_STATUS } from '../PurchaseMembershipModal/PurchaseModal.constants';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import { dataURLtoFile, getIpGlobal } from 'utils/utils';

const PurchaseModal = ({
  isOpen,
  onClose,
  onSubmit,
  oneToOne,
  paymentDataOneToOne,
  isPackage,
  isMonthlySessionSubscription,
  proseedHandlePurchase,
  updatePackageIntent,
  submitNow,
  showOnlyProseedModal,
  isInviteToJoin,
  handleUrlModal = true,
  inviteToJoin,
  isCouponAvailable,
}) => {
  const [showTerms, setShowTerms] = useState(false);
  const {
    query: { payment },
    history,
    location,
    domain,
  } = useRouter();
  const query = useRouter();
  const queryCode = query?.code === undefined ? null : query?.code?.includes('p_') ? null : query?.code;
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { states } = useSelector(state => state?.states);
  const { countries, loading: countriesLoading } = useSelector(state => state?.country);
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const [statesArray, setStatesArray] = useState([]);
  const [newUserSignedIn, setNewUserSignedIn] = useState(false);
  const isInviteToJoinCode = useSelector(state => state.contributions.inviteCode);
  const [isAlreadyPuchased, setIsAlreadyPuchased] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const isLoadingAccount = useSelector(({ account }) => account.isLoading);
  const errorAccount = useSelector(({ account }) => account.error?.message);
  const errorContribution = useSelector(({ contributions }) => contributions.error?.message);
  const couponCode = useSelector(state => state?.contributions?.couponCode);
  const priceId = useSelector(state => state.contributions.priceCode);
  const [smsReminderCheckBox, setSmsReminderCheckBox] = useState(false);
  const [showPhoneNumberFields, setshowPhoneNumberFields] = useState(true);
  const [isPhoneNumberRequired, setisPhoneNumberRequired] = useState(false);
  const [showErrorPhoneNumber, setShowErrorPhoneNumber] = useState(false);
  const [loginStep, setloginStep] = useState(false);
  const [phoneNumberField, setPhoneNumberField] = useState('');
  const [allowpassword, setallowpassword] = useState(false);
  const [paramsforEditUser, setParamsforEditUser] = useState({
    countryId: null,
    TimeZoneId: null,
    stateCode: null,
  });
  const contribution = useContribution();
  const { user } = useAccount();
  const [selectedTimeZone, setselectedTimeZone] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [showUnableJoin, setshowUnableJoin] = useState(false);
  const [isShowWaiver, setIsShowWaiver] = useState(false);
  const [waiverData, setWaiverData] = useState(null);
  const { checkEmail, isLoadingEmail } = useIsEmailExist();
  const dispatch = useDispatch();

  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
    if (!countries || (!countries?.length && !countriesLoading)) {
      dispatch(countryActions.fetchCountries());
    }
    if (!states || !states.length) {
      dispatch(countryActions.fetchStates());
    }
  }, [timeZones, timeZoneLoading]);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        checkPhoneNumber();
      } else {
        setshowPhoneNumberFields(false);
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }
    }
  }, []);
  const {
    type,
    id,
    title,
    paymentInfo: { paymentOptions },
    isElectronicSignatureActive,
    isPurchased,
  } = contribution;
  const [step, setStep] = useState(() => {
    if (!isNil(isInviteToJoin)) {
      return PURCHASE_MODAL_STEPS.join;
    }
    if (!isEmpty(user)) {
      return PURCHASE_MODAL_STEPS.loggedIn;
    }
    return PURCHASE_MODAL_STEPS.init;
  });
  const [lastStep, setLastStep] = useState(step);
  function isCountryNameAvailable(countryName, arrayOfObjects) {
    return arrayOfObjects.some(obj => obj.countryName === countryName);
  }
  useEffect(() => {
    if (!isEmpty(user)) {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        checkPhoneNumber();
      } else {
        setshowPhoneNumberFields(false);
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }
    }
  }, []);
  useEffect(() => {
    if (isEmpty(user) === false) {
      if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        checkProceedStatus();
      }
    }
  }, []);
  const handleProceedPurchaseStatus = useCallback(async () => {
    const userIp = await getIpGlobal();
    const action = await fetchClientContribution(id, userIp)(dispatch);
    if (action.type === FETCH_CONTRIBUTION_SUCCESS) {
      const isProceedStatus = [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(
        action.payload.purchaseStatus,
      );
      if (isProceedStatus) {
        return showOnlyProseedModal();
      }
    } else {
      return null;
    }
  }, [dispatch, id, showOnlyProseedModal]);

  const clearUrlSearch = () => {
    history.replace({
      search: '',
    });
  };
  const saveUserSignature = async values => {
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(values.clientSignature);
    setWaiverData({
      ...waiverData,
      clientName: values.clientName,
    });
    formData.append('file', sign);

    setIsShowWaiver(false);
    setLoadingPayment(false);
    submitUserSignature(values.clientSignature);
  };
  const isSignSaved = async signature => {
    let isSignedSubmitted = null;

    isSignedSubmitted = await submitUserSignature(signature);

    return isSignedSubmitted;
  };

  const getWaiverForm = async () => {
    setLoadingPayment(true);
    try {
      getSingleContract(contribution?.id).then(data => {
        setWaiverData({
          ...waiverData,
          formName: data?.formName,
          formDescription: data?.formDescription,
          formText: data?.formText,
          description: data?.formDescription,
          templateId: data.id,
          id: data.id,
          completeDescription: data?.formText,
        });
        setIsShowWaiver(true);
        setLoadingPayment(false);
      });
    } catch (e) {
      setLoadingPayment(false);
    }
    setLoadingPayment(false);
  };
  const submitUserSignature = async val => {
    let isSignedSubmitted = false;
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    const details = {
      contributionId: contribution.id,
      contractId: waiverData.id,
      ipAddress: userIP,
    };
    try {
      setSignedContract(formData, details);
      isSignedSubmitted = true;
    } catch (e) {
      console.log('Error Found', e);
      isSignedSubmitted = false;
    }
    setIsShowWaiver(false);
    setLoadingPayment(false);
    return isSignedSubmitted;
  };
  const setStepValue = useCallback(
    val => {
      setStep(val);
    },
    [step],
  );
  const saveSignature = async val => {
    let returnval = false;
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    await saveSignoffData(formData, id, userIP)
      .then(res => {
        if (res === 'Signoff data succesfully saved.') {
          returnval = true;
        }
      })
      .catch(err => {
        console.log('err', err);
      });
    return returnval;
  };
  const checkPhoneNumber = () => {
    if (!isEmpty(user)) {
      if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
        if (
          !isEmpty(user.subCategorySmsPermissions) &&
          (user.subCategorySmsPermissions.OneHourSession === true ||
            user.subCategorySmsPermissions.TwentyFourHourSession === true)
        ) {
          if (step === 'login' && loginStep === false) {
            setshowPhoneNumberFields(true);
            setShowErrorPhoneNumber(true);
            return false;
          } else {
            updatePhoneNumber();
            setshowPhoneNumberFields(false);
            return true;
          }
        }
        if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
          if (
            !isEmpty(user.subCategorySmsPermissions) &&
            (user.subCategorySmsPermissions.OneHourSession === true ||
              user.subCategorySmsPermissions.TwentyFourHourSession === true)
          ) {
            if (step === 'login' && loginStep === false) {
              setshowPhoneNumberFields(true);
              setloginStep(true);
              return false;
            }
            if (smsReminderCheckBox === true) {
              if (user?.phoneNo === null || user.phoneNo?.length === 0) {
                if (phoneNumberField.length === 0) {
                  setshowPhoneNumberFields(true);
                  setShowErrorPhoneNumber(true);
                  return false;
                } else {
                  if (user?.phoneNo != phoneNumberField) {
                    updatePhoneNumber();
                  }
                  setshowPhoneNumberFields(false);
                  return true;
                }
              } else {
                if (user?.phoneNo != phoneNumberField) {
                  if (phoneNumberField.length === 0) {
                    setShowErrorPhoneNumber(true);
                    return false;
                  } else {
                    updatePhoneNumber();
                  }
                }
                setshowPhoneNumberFields(false);
                return true;
              }
            } else {
              return true;
            }
          } else {
            setshowPhoneNumberFields(false);
            return true;
          }
        } else {
          setshowPhoneNumberFields(false);
          return true;
        }
        return true;
      } else {
        if (
          showErrorPhoneNumber === false &&
          isPhoneNumberRequired === false &&
          phoneNumberField.length != 0 &&
          step === 'login'
        ) {
          return true;
        }
        if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
          if (
            !isEmpty(user.subCategorySmsPermissions) &&
            (user.subCategorySmsPermissions.OneHourSession === true ||
              user.subCategorySmsPermissions.TwentyFourHourSession === true)
          ) {
            if ((step === 'login' || step === 'joinLogin') && loginStep === false) {
              setshowPhoneNumberFields(true);
              setloginStep(true);
              setStepValue('loggedIn');
              return false;
            }
            if (smsReminderCheckBox === true) {
              if (user?.phoneNo === null || user.phoneNo?.length === 0) {
                if (phoneNumberField.length === 0) {
                  setshowPhoneNumberFields(true);
                  setShowErrorPhoneNumber(true);
                  return false;
                } else {
                  if (user?.phoneNo != phoneNumberField) {
                    updatePhoneNumber();
                  }
                  setshowPhoneNumberFields(false);
                  return true;
                }
              } else {
                if (user?.phoneNo != phoneNumberField) {
                  if (phoneNumberField.length === 0) {
                    setShowErrorPhoneNumber(true);
                    return false;
                  } else {
                    updatePhoneNumber();
                  }
                }
                setshowPhoneNumberFields(false);
                return true;
              }
            } else {
              return true;
            }
          } else {
            setshowPhoneNumberFields(false);
            return true;
          }
        } else {
          setshowPhoneNumberFields(false);
          return true;
        }
      }
    } else if (isEmpty(user)) {
      if (
        showErrorPhoneNumber === false &&
        isPhoneNumberRequired === false &&
        (formRef.current.values.phoneNo.length != 0 || phoneNumberField.length != 0)
      ) {
        return true;
      } else {
        if (formRef.current.values.phoneNo.length === 0 || formRef.current.values.phoneNo === undefined) {
          setshowPhoneNumberFields(true);
          setShowErrorPhoneNumber(true);
          return false;
        } else {
          if (user?.phoneNo != formRef.current.values.phoneNo) {
            updatePhoneNumber();
          }
          setshowPhoneNumberFields(false);
          return true;
        }
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }

      return true;
    }
  };
  const updatePhoneNumber = async () => {
    await request('/Account/UpdatePhoneNo', 'POST', {
      phoneNumber: phoneNumberField || formRef.current.values.phoneNo,
    }).then(res => {
      let u = {
        ...user,
        phoneNo: phoneNumberField || formRef.current.values.phoneNo,
      };
      dispatch(updateUser(u));
    });
  };

  const checkCountry = async values => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    // const currentCountry = countries.find(obj => obj.name === data.country_name);
    const currentCountry = { id: '60b8ddb57205057e7ce2b861' };

    if (currentCountry.id != formRef.current.values.countryId) {
      if (currentCountry.id === '60b8ddb57205057e7ce2b861') {
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
        setStatesArray(filteredStates);
        setTimeZoneArray(timeZones);
        if (
          formRef?.current?.values?.TimeZoneId?.length > 0 &&
          formRef?.current?.values?.TimeZoneId != null &&
          formRef?.current?.values?.stateCode?.length > 0 &&
          formRef?.current?.values?.stateCode != null
        ) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, values.stateCode);
        } else {
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    } else {
      if (formRef.current.values.countryId === '60b8ddb57205057e7ce2b861') {
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values.countryId);
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        setStatesArray(filteredStates);
        if (formRef?.current?.values?.stateCode?.length > 0 && formRef?.current?.values?.stateCode != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, formRef.current.values.stateCode);
        } else {
          setLoadingPayment(false);
          return false;
        }
        if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
        } else {
          setLoadingPayment(false);
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === formRef.current.values.countryId);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0]?.countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: values?.countryId, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);

          if (formRef.current.values.TimeZoneId.length > 0 && formRef.current.values.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    }
  };
  const checkProceedStatus = async () => {
    if (!isEmpty(user)) {
      if (formRef) {
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.timeZoneId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
      }
      const res = await fetch('https://geolocation-db.com/json/');
      const data = await res.json();
      const currentCountry = countries.find(obj => obj.name === data.country_name);
      // const currentCountry = { id: '60b8ddb57205057e7ce2b861' };
      const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
      const filteredObjects = states.filter(obj => obj.countryId === currentCountry.id);
      setStatesArray(filteredObjects);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
    }
  };
  const stepVerifier = useCallback(
    async values => {
      let { Signature } = values;
      if (!isEmpty(user)) {
        // if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        //   formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user?.countryId);
        //   setselectedTimeZone(user.timeZoneId);
        //   formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user?.timeZoneId);
        //   formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user?.stateCode);
        // }
        if (user?.id === contribution?.userId && paymentOptions != PAYMENT_OPTIONS.FREESESSIONPACKAGE) {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
          // setshowUnableJoin(true);
          return false;
        }
        // if (contribution?.type === ContributionType.contributionOneToOne) {
        //   setLastStep(step);
        //   setStep(PURCHASE_MODAL_STEPS.loggedIn);
        //   return false;
        // }

        return true;
      }
      if (step === PURCHASE_MODAL_STEPS.create) {
        setNewUserSignedIn(true);
        const returnedRegisterAction = await completeRegister({
          ...values,
          userView: UserRoles.client,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          return formRef.current?.handleSubmit();
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinCreate) {
        setNewUserSignedIn(true);
        dispatch({
          type: 'PURCHASE_DUMMY',
          payload: false,
        });
        const returnedRegisterAction = await completeRegister({
          ...values,
          userView: UserRoles.client,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          joinContribution({ id, accessCode: isInviteToJoin })
            .then(() => {
              dispatch(
                fetchContributionActions.success({
                  ...contribution,
                  isPurchased: true,
                  purchaseStatus: 'succeeded',
                  subscriptionStatus: { status: 'active' },
                }),
              );
              dispatch({
                type: 'PURCHASE_DUMMY',
                payload: true,
              });
            })
            .then(() => {
              // onJoin(true);
            });
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.login) {
        const { Email, Password, otp } = values;
        const returnedLoginAction = await login(Email, Password, null, null, null, null, null, null, otp)(dispatch);
        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          if (!oneToOne) {
            handleProceedPurchaseStatus();
          }
          return formRef.current?.handleSubmit();
        }

        const { payload } = returnedLoginAction || {};

        setLoginErrorMessage(payload?.message);

        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinLogin) {
        const { Email, Password, otp } = values;
        const returnedLoginAction = await login(
          Email,
          Password,
          null,
          null,
          null,
          true,
          contribution?.userId,
          otp,
        )(dispatch);
        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          joinContribution({ id, accessCode: isInviteToJoin })
            .then(() => {
              dispatch(
                fetchContributionActions.success({
                  ...contribution,
                  isPurchased: true,
                  purchaseStatus: 'succeeded',
                  subscriptionStatus: { status: 'active' },
                }),
              );
            })
            .then(() => {
              if (!oneToOne) {
                handleProceedPurchaseStatus();
              }
              handleProceedPurchaseStatus();
              return formRef.current?.handleSubmit();
            });
        }
        return false;
      }

      const isExistEmail = await checkEmail(values[ACCOUNT_FORM_FIELDS.email]);
      setallowpassword(isExistEmail);
      setisPhoneNumberRequired(isExistEmail.phNumberRequired);
      if (isExistEmail) {
        if (isInviteToJoin || queryCode) {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.joinLogin);
        } else {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.login);
        }
        if (
          isExistEmail.phNumberRequired === true &&
          contribution?.smsPermissionsPerContribution?.SessionReminder === true
        ) {
          setshowPhoneNumberFields(true);
        }
      } else if (isInviteToJoin || queryCode) {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.joinCreate);
      } else {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.create);
      }
      return false;
    },
    [
      user,
      step,
      checkEmail,
      isInviteToJoin,
      dispatch,
      id,
      query.code,
      contribution,
      // onJoin,
      handleProceedPurchaseStatus,
      smsReminderCheckBox,
      setSmsReminderCheckBox,
      phoneNumberField,
      setPhoneNumberField,
    ],
  );
  const [isSuccessPurchaseModalOpen, setIsSuccessPurchaseModalOpen] = useState(
    handleUrlModal && payment === PAYMENTS_STATUS.SUCCESS,
  );
  const [isFailedPurchaseModalOpen, setIsFailedPurchaseModalOpen] = useState(
    handleUrlModal && payment === PAYMENTS_STATUS.FAILED,
  );
  const handleSuccessModalClose = useCallback(() => {
    setIsSuccessPurchaseModalOpen(false);
    clearUrlSearch();
  }, []);
  const handleFailedModalClose = useCallback(() => {
    setIsFailedPurchaseModalOpen(false);
    clearUrlSearch();
  }, []);

  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  const modalTitle = isInviteToJoin
    ? PURCHASE_MODAL_TITLES[type]?.join(title, !isEmpty(user))
    : PURCHASE_MODAL_TITLES[type]?.modal;

  const formRef = useRef(null);

  const handleCloseAlreadyPurchased = () => {
    setIsAlreadyPuchased(false);
    onClose();
  };

  useEffect(() => {
    if (submitNow) {
      // eslint-disable-next-line no-unused-expressions
      formRef.current?.handleSubmit();
    }
  }, [submitNow, formRef]);

  const stripe = useStripe();
  const elements = useElements();
  const { request, loading } = useHttp();
  const [typeOfPayment, setTypeOfPayment] = useState(
    oneToOne && paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION)
      ? PAYMENT_OPTIONS.PER_SESSION
      : paymentOptions.sort()[0],
  );
  const [summary, setSummary] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);

  const [coupan_id, setcoupan_id] = useState(null);
  const summaryRef = useRef();
  summaryRef.current = summary;
  const [name, setName] = useState(null);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [error, setError] = useState(null);

  const handlePayResponse = useCallback(
    response => {
      setLoadingPayment(false);

      if (response.error) {
        setError(response.error.message);
      } else {
        onSubmit();
      }
    },
    [onSubmit],
  );

  // Payment method
  const payWith3DSecure = useCallback(
    (...args) => {
      setLoadingPayment(true);

      stripe
        .confirmCardPayment(...args)
        .then(handlePayResponse)
        .catch(console.dir);
    },
    [stripe, handlePayResponse],
  );

  // Entire payment flow: prepareEntire => payEntireCourse => confirm 3d secure
  const payEntireCourse = useCallback(
    data => {
      const { clientSecret } = data;
      const card = elements.getElement(CardNumberElement);

      payWith3DSecure(clientSecret, {
        payment_method: {
          card,
          billing_details: { name },
        },
      });
    },
    [elements, name, payWith3DSecure],
  );

  // TODO: change namings
  const prepareEntire = useCallback(
    data => {
      let apiUrl = '/api/purchase/course/checkout';
      if (oneToOne) {
        apiUrl = '/api/purchase/one-to-one';
        data = {
          ...data,
          isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
          isPhoneNumberAdded: contribution.isPhoneNumberAdded,
          isCoachNumberAdded: contribution.isCoachNumberAdded,
          priceId: priceId,
        };
        if (isMonthlySessionSubscription) {
          apiUrl += '/monthly-session-subscription';
        } else if (isPackage) {
          apiUrl += '/package';
        }
      }
      request(apiUrl, 'POST', data)
        // .then(payEntireCourse)
        .then(res => {
          // handle 100% discount without checkout
          if (res === '100discount' || isInviteToJoin || res.includes('Free')) {
            // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
            setTimeout(() => {
              onClose();
              // window.location.reload();
            }, 1000);
            // handleProceedPurchaseStatus();
            // setLoadingPayment(false);
          } else {
            if (contribution?.paymentType === 'Advance') {
              return redirectTo(res);
            } else {
              return stripe.redirectToCheckout({ sessionId: res });
            }
          }
        })
        .catch(e => {
          if (e?.response?.data.includes('Your are not booked for this session or this session is already booked.')) {
            setErrorModal(true);
          }
          if (e?.response?.status === 400) {
            if (isInviteToJoin) {
              window.location.reload();
            } else {
              return setIsAlreadyPuchased(true);
            }
          }
          console.dir(e);
        });
    },
    [request, smsReminderCheckBox, setSmsReminderCheckBox, phoneNumberField, setPhoneNumberField /*, payEntireCourse*/],
  );

  // Split payments flow: createTokenForSplitPayments => attachPaymentMethodByToken => subscribe => confirm 3d secure
  const subscribe = useCallback(
    ({ paymentMethodId }) => {
      const data = {
        paymentMethodId,
        paymentOptions: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        paymentOption: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        contributionId: contribution.id,
        couponId: coupan_id ? coupan_id : summaryRef?.current?.coupon?.id,
      };

      const purchaseType = oneToOne ? ContributionPurchaseType.oneToOne : ContributionPurchaseType.course;
      const paymentOption = isMonthlySessionSubscription ? '/monthly-session-subscription' : '';
      const paymentUrl = `/api/purchase/${purchaseType}${paymentOption}`;

      request(paymentUrl, 'POST', data)
        .then(res => {
          if (res.clientSecret) {
            if (isMonthlySessionSubscription) {
              payWith3DSecure(res.clientSecret);
            }
          } else {
            onSubmit();
          }
        })
        .catch(console.dir);
    },
    [request, contribution.id, payWith3DSecure, oneToOne, isMonthlySessionSubscription],
  );

  const attachPaymentMethodByToken = useCallback(
    cardToken => {
      request('/api/payment/attach-customer-payment-method-token', 'POST', {
        cardToken,
        contributionId: contribution.id,
      })
        .then(subscribe)
        .catch(console.dir);
    },
    [request, subscribe],
  );

  // TODO: change this to use stripe checkout
  const createTokenForSplitPayments = useCallback(() => {
    const card = elements.getElement(CardNumberElement);
    stripe.createToken(card).then(res => {
      if (!res.error) {
        attachPaymentMethodByToken(res.token.id);
      }
    });
  }, [stripe, elements, attachPaymentMethodByToken]);
  const updateUserCall = async () => {
    const updatedUser = {
      ...user,
      id: user.id,
      stateCode: formRef.current.values.stateCode,
      timeZoneId: formRef.current.values.TimeZoneId,
      countryId: formRef.current.values.countryId,
    };
    await editUserProfile(user.id, updatedUser).then(async res => {
      let u = {
        ...user,
        countryId: res.user.countryId,
        timeZoneId: res.user.timeZoneId,
        stateCode: res.user.stateCode,
      };
      dispatch(updateUser(u));
    });
  };
  // Submitting form when user clicks on purchase button
  const handleSubmit = useCallback(
    async values => {
      formRef.current.setTouched({});
      const isCanProceed = await stepVerifier(values);
      if (!isCanProceed) {
        return;
      }

      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true && smsReminderCheckBox === true) {
        const phoneNumberAdded = checkPhoneNumber();
        if (!phoneNumberAdded) {
          setshowPhoneNumberFields(true);
          setLoadingPayment(false);
          return;
        }
      }
      if (
        contribution.paymentType === 'Simple' &&
        contribution.taxType != 'No' &&
        isInviteToJoin === null &&
        newUserSignedIn === false &&
        !isMonthlySessionSubscription &&
        ((contribution.paymentInfo.paymentOptions.length === 1 &&
          contribution.paymentInfo.paymentOptions.includes('Free')) ||
          (contribution.paymentInfo.paymentOptions.length === 1 &&
            contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage')) ||
          (contribution.paymentInfo.paymentOptions.length === 2 &&
            contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage') &&
            contribution.paymentInfo.paymentOptions.includes('Free'))) === false
      ) {
        const isCheckCountry = await checkCountry(values);
        if (!isCheckCountry) {
          setLoadingPayment(false);
          return;
        } else {
          await updateUserCall();
        }
      }
      if (contribution.customWaiverId != null && isElectronicSignatureActive) {
        if (step != PURCHASE_MODAL_STEPS.init && step != PURCHASE_MODAL_STEPS.join) {
          if (formRef.current.isValid) {
            getWaiverForm();
          }
        } else {
          stepVerifier(formRef.current.values);
        }
      } else {
        if (proseedHandlePurchase && !isPackage) {
          return proseedHandlePurchase(summary?.coupon?.id ? summary?.coupon?.id : null);
        }

        setName(values.Name);
        setError(null);
        if (oneToOne) {
          if (isMonthlySessionSubscription) {
            createTokenForSplitPayments();
            // const data = {
            //   paymentOption: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
            //   contributionId: contribution.id,
            //   couponId: summaryRef?.current?.coupon?.id,
            // };
            // prepareEntire(data);
            return;
          } /*else if (isPackage) {
          const data = {
            contributionId: contribution.id,
            couponId: summaryRef?.current?.coupon?.id,
          };
          prepareEntire(data);
          return;
        }*/ else if (
            isPackage
          ) {
            try {
              let paramData;
              if (summary?.coupon?.id != null) {
                paramData = {
                  contributionId: id,
                  couponId: summary?.coupon?.id ? summary?.coupon?.id : null,
                  IsPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
                  isPhoneNumberAdded: contribution.isPhoneNumberAdded,
                  isCoachNumberAdded: contribution.isCoachNumberAdded,
                  priceId: priceId,
                  // createSingleSession: false,
                };
              } else {
                paramData = {
                  contributionId: id,
                  accessCode: isInviteToJoinCode,
                  isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
                  isPhoneNumberAdded: contribution.isPhoneNumberAdded,
                  isCoachNumberAdded: contribution.isCoachNumberAdded,
                  priceId: priceId,
                };
              }
              const res = await request('/api/Purchase/one-to-one/pkg', 'POST', paramData);
              if (res === '100discount') {
                window.location.reload();
                return;
              } else if (contribution?.paymentType === 'Advance') {
                return redirectTo(res);
              } else {
                return stripe.redirectToCheckout({ sessionId: res });
              }
            } catch (err) {
              console.log('err->', err);
            }
            dispatch(
              fetchContributionActions.success({
                ...contribution,
                isPurchased: true,
                purchaseStatus: 'succeeded',
                subscriptionStatus: { status: 'active' },
              }),
            );
            // history.push(`/contribution-view/${id}/sessions`);
            if (contribution?.clientRedirectLink) {
              history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
            } else {
              history.push(`/contribution-view/${id}/sessions`);
            }
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            // payEntireCourse(paymentDataOneToOne);
            // return;
          }
          if (!isPackage) {
            prepareEntire({
              ...paymentDataOneToOne,
              couponId: summaryRef?.current?.coupon?.id,
              createSingleSession: true,
              priceId: priceId,
            });
          }
        }
        If(typeOfPayment === 'EntireCourse' || typeOfPayment === 'SplitPayments')
          .then(
            mapTo({
              contributionId: contribution.id,
              // paymentOptions: typeOfPayment,
              paymentOption: typeOfPayment,
              couponId: summaryRef?.current?.coupon?.id,
            }),
          )
          .then(prepareEntire)
          .else(createTokenForSplitPayments);
      }
    },
    [
      stepVerifier,
      proseedHandlePurchase,
      oneToOne,
      typeOfPayment,
      contribution.id,
      prepareEntire,
      createTokenForSplitPayments,
      // payEntireCourse,
      paymentDataOneToOne,
      setName,
      timeZones,
      id,
      smsReminderCheckBox,
      setSmsReminderCheckBox,
      phoneNumberField,
      setPhoneNumberField,
    ],
  );

  // TODO : check if needed and fix
  useEffect(() => {
    if (payment === PAYMENTS_STATUS.SUCCESS) {
      setLoadingPayment(false);
      onSubmit();
    }
  }, [payment]);

  // Get summary for payment
  useEffect(() => {
    setError(null);
    getSummary();
  }, [typeOfPayment, contribution.id, oneToOne, isPackage, isMonthlySessionSubscription]);

  const getSubmitButtonTitle = () => {
    if ((isEmpty(user) && step === PURCHASE_MODAL_STEPS.init) || step === PURCHASE_MODAL_STEPS.join) {
      return !isEmpty(user) && isInviteToJoin ? 'Ok' : 'Next';
    }
    return PURCHASE_MODAL_TITLES[type].submit;
  };

  const getSummary = coupon => {
    let paymentType = `${isPackage ? 'SessionsPackage' : typeOfPayment}`;
    paymentType = `${isMonthlySessionSubscription ? 'MonthlySessionSubscription' : paymentType}`;

    const REQUEST_MAPPING = {
      [ContributionType.contributionOneToOne]: purchaseService.getOnToOnePaymentInfo,
      [ContributionType.contributionCourse]: purchaseService.getCoursePaymentInfo,
      [ContributionType.contributionMembership]: purchaseService.getMembershipPaymentInfo,
      [ContributionType.contributionCommunity]: purchaseService.getCommunityPaymentInfo,
    };

    const getPaymentData = REQUEST_MAPPING[contribution.type];

    getPaymentData(contribution.id, paymentType, coupon?.id)
      .then(data => {
        setSummary({ ...data, coupon: coupon });
        if (user && coupon) {
          updatePackageIntent({
            contributionId: contribution.id,
            couponId: coupon?.id || couponCode,
          });
        }
      })
      .catch(console.dir);
  };

  const handleRedeem = () => {
    const {
      current: { values },
    } = formRef;
    if (values?.Coupon?.length > 0) {
      request(`/Coupons/ValidateByName/${values.Coupon}/${contribution.id}/${typeOfPayment}`, 'GET').then(res => {
        dispatch(setCouponCode(res.id));
        if (res?.percentAmount > 0 || res?.discountAmount > 0) {
          setcoupan_id(res.id);
          getSummary(res);
        }
      });
    }
  };

  const isShowInput = (step, input) => {
    const visible = {
      init: [ACCOUNT_FORM_FIELDS.email],
      join: [ACCOUNT_FORM_FIELDS.email],
      loggedIn: [],
      joinLogin: [ACCOUNT_FORM_FIELDS.email, ACCOUNT_FORM_FIELDS.password, ACCOUNT_FORM_FIELDS.signature, RESTORE_PASS],
      login: [ACCOUNT_FORM_FIELDS.email, ACCOUNT_FORM_FIELDS.password, ACCOUNT_FORM_FIELDS.signature, RESTORE_PASS],
      joinCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.signature,
      ],
      create: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.signature,
      ],
    };
    return visible[step]?.includes(input);
  };

  const showPadForLoggedInClient = !isEmpty(user) && step === PURCHASE_MODAL_STEPS.loggedIn && !isPurchased;

  const disableJoinBtn =
    (disableBtn &&
      isElectronicSignatureActive &&
      isShowInput(step, ACCOUNT_FORM_FIELDS.signature) &&
      contribution.customWaiverId === null) ||
    (disableBtn && isElectronicSignatureActive && showPadForLoggedInClient && contribution.customWaiverId === null) ||
    (inviteToJoin && !isEmpty(user));

  const setDisableValue = val => {
    setDisableBtn(val);
  };

  const getCardInitialValues = pkg => {
    let val;
    if (pkg) {
      val = {
        [ACCOUNT_FORM_FIELDS.confirmEmail]: '',
        [ACCOUNT_FORM_FIELDS.email]: '',
        [ACCOUNT_FORM_FIELDS.password]: '',
        [ACCOUNT_FORM_FIELDS.firstName]: '',
        [ACCOUNT_FORM_FIELDS.lastName]: '',
        [DISCOUNT_CODE_FIELDS.coupon]: '',
        [ACCOUNT_FORM_FIELDS.signature]: null,
        [ACCOUNT_FORM_FIELDS.timeZoneId]: '',
        [ACCOUNT_FORM_FIELDS.country]: '',
        [ACCOUNT_FORM_FIELDS.state]: '',
        [ACCOUNT_FORM_FIELDS.sessionReminder]: false,
        [ACCOUNT_FORM_FIELDS.phone]: '',
      };
    } else {
      val = {
        Name: '',
        cardNumber: false,
        cardExpired: false,
        cardCVC: false,
        [ACCOUNT_FORM_FIELDS.confirmEmail]: '',
        [ACCOUNT_FORM_FIELDS.email]: '',
        [ACCOUNT_FORM_FIELDS.password]: '',
        [ACCOUNT_FORM_FIELDS.firstName]: '',
        [ACCOUNT_FORM_FIELDS.lastName]: '',
        [DISCOUNT_CODE_FIELDS.coupon]: '',
        [ACCOUNT_FORM_FIELDS.signature]: null,
        [ACCOUNT_FORM_FIELDS.timeZoneId]: '',
        [ACCOUNT_FORM_FIELDS.country]: '',
        [ACCOUNT_FORM_FIELDS.state]: '',
        [ACCOUNT_FORM_FIELDS.sessionReminder]: false,
        [ACCOUNT_FORM_FIELDS.phone]: '',
      };
    }
    return val;
  };
  return (
    <>
      <Modal
        hiddenCancel={!isEmpty(user) && isInviteToJoin}
        cancelTitle={!isEmpty(user) && isInviteToJoin ? 'Ok' : 'Cancel'}
        cancelInvert={!isInviteToJoin}
        disableConfirm={!isEmpty(user) && isInviteToJoin && contribution?.customWaiverId === null}
        title={modalTitle}
        isOpen={isOpen}
        onCancel={onClose}
        submitTitle={getSubmitButtonTitle()}
        form="credit-card-form"
        loading={loading || loadingPayment || isLoadingEmail || isLoadingAccount || disableJoinBtn}
        helperText={
          isEmpty(user) &&
          step !== PURCHASE_MODAL_STEPS.init &&
          step !== PURCHASE_MODAL_STEPS.join &&
          contribution?.customWaiverId === null &&
          (errorAccount || errorContribution || 'Please fill out account information')
        }
        applyTheming={isDarkThemeEnabled}
      >
        <Formik
          initialValues={getCardInitialValues(isPackage)}
          validationSchema={getPurchaseValidationSchema(step, isMonthlySessionSubscription && !isPackage)}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ handleChange, errors, touched, setTouched, setFieldValue }) => {
            return (
              <PurchaseModalForm
                isInviteToJoin={isInviteToJoin}
                setTouched={setTouched}
                typeOfPayment={typeOfPayment}
                setTypeOfPayment={setTypeOfPayment}
                loading={loading}
                summary={summary}
                isPackage={isPackage}
                isMonthlySessionSubscription={isMonthlySessionSubscription}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleRedeem={handleRedeem}
                errors={errors}
                touched={touched}
                formRef={formRef}
                error={error}
                setShowTerms={setShowTerms}
                setPhoneNumberField={setPhoneNumberField}
                phoneNumberField={phoneNumberField}
                setShowErrorPhoneNumber={setShowErrorPhoneNumber}
                showErrorPhoneNumber={showErrorPhoneNumber}
                setSmsReminderCheckBox={setSmsReminderCheckBox}
                smsReminderCheckBox={smsReminderCheckBox}
                setshowPhoneNumberFields={setshowPhoneNumberFields}
                showPhoneNumberFields={showPhoneNumberFields}
                step={step}
                paymentIntentCreated={paymentDataOneToOne?.created}
                sessionLifeTimeSeconds={paymentDataOneToOne?.sessionLifeTimeSeconds}
                onCheckoutSessionExpired={() => onClose(false)}
                setDisableValue={setDisableValue}
                isCouponAvailable={isCouponAvailable}
              />
            );
          }}
        </Formik>

        <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      </Modal>
      <SuccessfulPurchaseModal isOpen={isSuccessPurchaseModalOpen} handleClose={handleSuccessModalClose} />
      <FailedPurchaseModal isOpen={isFailedPurchaseModalOpen} handleClose={handleFailedModalClose} />

      {isAlreadyPuchased && (
        <Modal
          isOpen
          onCancel={handleCloseAlreadyPurchased}
          onSubmit={handleCloseAlreadyPurchased}
          title="Purchased contribution"
          hiddenCancel
        >
          <LabelText>
            {ContributionType.contributionOneToOne
              ? 'Not Supported Payment Option'
              : 'You have already purchased this contribution'}
          </LabelText>
        </Modal>
      )}
      {errorModal && (
        <Modal
          isOpen={errorModal}
          onCancel={() => {
            setErrorModal(false);
          }}
          onSubmit={() => {
            setErrorModal(false);
          }}
          title=""
          hiddenCancel
          submitTitle="Ok"
        >
          <LabelText style={{ color: contribution?.isDarkModeEnabled ? 'white' : 'black' }}>
            You are not booked for this session or this session is already booked.
          </LabelText>
        </Modal>
      )}
      {isShowWaiver && (
        <CreateCustomWaiver
          isOpen={isShowWaiver}
          onSubmit={values =>
            saveUserSignature(values).then(async () => {
              saveSignature(values);
              if (proseedHandlePurchase && !isPackage) {
                return proseedHandlePurchase(summary?.coupon?.id ? summary?.coupon?.id : null);
              }

              setName(values.Name);
              setError(null);
              if (oneToOne) {
                if (isMonthlySessionSubscription) {
                  createTokenForSplitPayments();
                  // const data = {
                  //   paymentOption: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
                  //   contributionId: contribution.id,
                  //   couponId: summaryRef?.current?.coupon?.id,
                  // };
                  // prepareEntire(data);
                  return;
                } /*else if (isPackage) {
                const data = {
                  contributionId: contribution.id,
                  couponId: summaryRef?.current?.coupon?.id,
                };
                prepareEntire(data);
                return;
              }*/ else if (
                  isPackage
                ) {
                  try {
                    let paramData;
                    if (summary?.coupon?.id != null) {
                      paramData = {
                        contributionId: id,
                        couponId: summary?.coupon?.id ? summary?.coupon?.id : null,
                        IsPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
                        isPhoneNumberAdded: contribution.isPhoneNumberAdded,
                        isCoachNumberAdded: contribution.isCoachNumberAdded,
                        priceId: priceId,
                        // createSingleSession: false,
                      };
                    } else {
                      paramData = {
                        contributionId: id,
                        accessCode: isInviteToJoinCode,
                        isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
                        isPhoneNumberAdded: contribution.isPhoneNumberAdded,
                        isCoachNumberAdded: contribution.isCoachNumberAdded,
                        priceId: priceId,
                      };
                    }
                    const res = await request('/api/Purchase/one-to-one/pkg', 'POST', paramData);
                    if (res === '100discount') {
                      window.location.reload();
                      return;
                    } else if (contribution?.paymentType === 'Advance') {
                      return redirectTo(res);
                    } else {
                      return stripe.redirectToCheckout({ sessionId: res });
                    }
                  } catch (err) {
                    console.log('err->', err);
                  }
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                  // history.push(`/contribution-view/${id}/sessions`);
                  if (contribution?.clientRedirectLink) {
                    history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
                  } else {
                    history.push(`/contribution-view/${id}/sessions`);
                  }
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                  // payEntireCourse(paymentDataOneToOne);
                  // return;
                }
                if (!isPackage) {
                  prepareEntire({
                    ...paymentDataOneToOne,
                    couponId: summaryRef?.current?.coupon?.id,
                    createSingleSession: true,
                    priceId: priceId,
                  });
                }
              }
              If(typeOfPayment === 'EntireCourse' || typeOfPayment === 'SplitPayments')
                .then(
                  mapTo({
                    contributionId: contribution.id,
                    // paymentOptions: typeOfPayment,
                    paymentOption: typeOfPayment,
                    couponId: summaryRef?.current?.coupon?.id,
                  }),
                )
                .then(prepareEntire)
                .else(createTokenForSplitPayments);
            })
          }
          contribution={contribution}
          waiverData={waiverData}
          isDarkModeAllowed={true}
          onCancel={() => setIsShowWaiver(false)}
          isCreateContribution={false}
        />
      )}
      {showUnableJoin && (
        <Modal
          title="Unable to join"
          isOpen={showUnableJoin}
          submitTitle="Close"
          hiddenCancel
          onCancel={() => {
            setshowUnableJoin(false);
          }}
          dontCancelOnSideClick
          onSubmit={() => {
            setshowUnableJoin(false);
          }}
          style={{ zIndex: '2000' }}
        >
          <p>Please use another email - you entered the account holder email of this service.</p>
        </Modal>
      )}
    </>
  );
};

PurchaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  oneToOne: PropTypes.bool,
  paymentDataOneToOne: PropTypes.shape({
    clientSecret: PropTypes.string,
  }),
  isPackage: PropTypes.bool,
  isMonthlySessionSubscription: PropTypes.bool,
  proseedHandlePurchase: PropTypes.func,
  updatePackageIntent: PropTypes.func,
  submitNow: PropTypes.bool.isRequired,
  showOnlyProseedModal: PropTypes.func,
};

PurchaseModal.defaultProps = {
  oneToOne: false,
  paymentDataOneToOne: null,
  isPackage: false,
  isMonthlySessionSubscription: false,
  proseedHandlePurchase: null,
  updatePackageIntent: null,
  showOnlyProseedModal: null,
  isInviteToJoin: null,
};

export default PurchaseModal;
