import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from 'components/FormUI/Button';
import useScreenView from 'hooks/useScreenView';
import classes from './coherereferals.module.scss';
import WelcomeReferals from 'assets/welcome_referrals.png';
import styled from 'styled-components';
import { useTheme, useMediaQuery, Tooltip, TextField, Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ShareIcon from 'assets/ic-share-white.svg';
import RevenueDetails from './Components/RevenueDetails';
import CohereAffilliateReferals from './Components/CohereAffiliateReferals';
import ExportIcon from 'pages/Contacts/Components/Icons/ExportIcon';
import CohereAffiliatePayouts from './Components/CohereAffiliatePayouts';
import InviteCoachesModal from '../Modals/InviteCoachesModal/InviteCoaches.Modal';
import WelcomeCohereAffiliatesModal from '../Modals/WelcomeCohereAffiliate/WelcomeCohereAffiliates.Modal';
import WelcomeCohereAffiliates from '../Modals/WelcomeCohereAffiliate/WelcomeCohereAffiliates.Modal';
import ManageBankSettings from '../Modals/ManageBankSettingsModal/ManageBankSettings.Modal';
import { updateUser } from 'actions/update-user';
import {
  downloadEnrollmentdetails,
  getAffiliateCoach,
  getContributionAffiliateDataApi,
  getEnrollmentStatus,
  toggleAffiliateEnrollment,
} from 'services/affiliate.service';
import useAccount from 'hooks/useAccount';
import ConnectStripeModal from 'components/Modals/ConnectStripeModal';
import { redirectTo } from 'services/links';
import { downloadFile } from 'utils/utils';
import { useHttp } from 'hooks/index';
import { useDispatch } from 'react-redux';

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  z-index: 1;
  margin-left: 10px;
`;
export default function CohereReferalsDetailPage() {
  const { mobileView } = useScreenView();
  const { user } = useAccount();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [value, setValue] = useState('Referrals');
  const [showBankSettings, setShowBankSettings] = useState(false);
  const [showWelcomModal, setShowWelcomModal] = useState(user.showFirstTimeAffiliatePopup);
  const [modalVisible, setModalVisible] = useState(false);
  const [stripeUrl, setStripeUrl] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(true);
  const [showExport, setShowExport] = useState(false);

  const dispatch = useDispatch();
  const { request } = useHttp();
  useEffect(() => {
    setLoader(true);
    getEnrollmentStatus()
      .then(enrollmentStatus => setIsEnrolled(enrollmentStatus))
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, [setIsEnrolled]);
  const onVerificate = useCallback(() => {
    request('api/Payment/generate-account-verification-link?forAffiliateAccount=true', 'POST').then(({ link }) => {
      setStripeUrl(link);
      setModalVisible(true);
    });
  }, [request]);
  const handlerEnrollClick = () => {
    if (user?.isAffiliateCoachEnable === false) {
      getAffiliateCoach()
        .then(res => {
          setModalVisible(true);
          setStripeUrl(res);
        })
        .catch(err => {
          console.log('error occured', err.response);
          if (err.response.data === 'User already has a stripe affiliate account') {
            onVerificate();
          }
        });
    } else if (user.affiliateAccountTransfersEnabled === false) {
      onVerificate();
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleCloseModalNow = () => {
    setModalVisible(false);
    redirectTo(stripeUrl);
  };
  const disablePopUp = async () => {
    await request('/Account/DisableAffiliateFirstTimePopup', 'POST').then(res => {
      let u = {
        ...user,
        showFirstTimeAffiliatePopup: false,
      };
      dispatch(updateUser(u));
    });
  };

  console.log('User------->', user.showFirstTimeAffiliatePopup, user);
  return (
    <div className={classes.container}>
      <div className={classes.buttons_container}>
        <Grid
          style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}
          container
          xs={12}
          sm={12}
          lg={12}
        >
          <Grid container xs={12} sm={12} lg={mobileView ? 12 : 4}>
            <Button
              className={classes.welcome_button}
              autoWidth
              onClick={() => {
                setShowWelcomModal(true);
              }}
            >
              Welcome to the Cohere Affiliate Program
              <StyledImg previewImg={WelcomeReferals} />
            </Button>
          </Grid>
          <div className={classes.rightbuttoncontainer}>
            <Grid style={{ display: 'flex', gap: '10px' }} container xs={12} sm={12} lg={12}>
              {user?.isAffiliateCoachEnable === false || user.affiliateAccountTransfersEnabled === false ? (
                <>
                  <div className={classes.mainContainer}>
                    {/* <div className={classes.automatedPayout}>Automated Payout Pending</div>
                <Tooltip
                  title="This folder contains resources that will make it easier to share Cohere with your friend, family and colleagues."
                  arrow
                >
                  <InfoIcon htmlColor="#C9B382" />
                </Tooltip> */}
                  </div>
                  <Button invert className={classes.welcome_button_invert} autoWidth onClick={handlerEnrollClick}>
                    Verify & Setup Payouts
                  </Button>
                </>
              ) : (
                <Button
                  invert
                  className={classes.welcome_button_invert}
                  autoWidth
                  onClick={() => {
                    setShowBankSettings(true);
                  }}
                >
                  Manage Bank Settings
                </Button>
              )}
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                <Button invert className={classes.welcome_button_invert} autoWidth onClick={() => {}}>
                  Affiliate Resources
                </Button>
                <Tooltip
                  title="This folder contains resources that will make it easier to share Cohere with your friend, family and colleagues."
                  arrow
                >
                  <InfoIcon htmlColor="#C9B382" />
                </Tooltip>
              </div>
              <Button
                className={classes.welcome_button}
                autoWidth
                onClick={() => {
                  setShowInviteModal(true);
                }}
              >
                <Icon src={ShareIcon} />
                Invite Coaches
              </Button>
            </Grid>
          </div>
        </Grid>
      </div>
      <RevenueDetails />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflowX: mobileView ? 'scroll' : 'unset',
          marginTop: '10px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {['Referrals'].map((item, index) => {
            return (
              <div
                style={{
                  // borderBottom: item === value ? `3px solid #215C73` : 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  // alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <Button
                  variant="contained"
                  value={item}
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '16px',
                    fontWeight: value != item ? '400' : '800',
                    color: '#000000DE',
                    minWidth: 'auto',
                    fontFamily: 'Avenir',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '1rem 0px',
                    letterSpacing: '0.15px',
                  }}
                  onClick={e => {
                    if (value != item) {
                      setValue(item);
                    }
                  }}
                >
                  {item}
                </Button>
              </div>
            );
          })}
        </div>
        {showExport && (
          <Button
            onClick={async () => {
              if (value === 'Referrals') {
                await downloadEnrollmentdetails().then(res => {
                  downloadFile(res, 'AffiliateTracking.csv');
                });
              }
            }}
            invert
            autoWidth
            className={classes.export_button}
          >
            <ExportIcon width="16" height="16" />
            Export
          </Button>
        )}
      </Box>
      {value === 'Referrals' ? (
        <CohereAffilliateReferals showExport={showExport} setShowExport={setShowExport} />
      ) : (
        <CohereAffiliatePayouts />
      )}
      <InviteCoachesModal setShowInviteModal={setShowInviteModal} showInviteModal={showInviteModal} />
      <WelcomeCohereAffiliates
        isOpen={showWelcomModal}
        onSubmit={() => {
          setShowWelcomModal(false);
        }}
        onCancel={() => {
          setShowWelcomModal(false);
          disablePopUp();
        }}
        width={'600px'}
        maxWidth="600px"
        minWidth={!mobileView ? '600px' : ''}
        hiddenCancel
        disableFooter
        CrossIconHide
        ignoreDisable
      />
      <ManageBankSettings setShowBankSettings={setShowBankSettings} showBankSettings={showBankSettings} />
      {modalVisible && (
        <ConnectStripeModal
          isOpen={modalVisible}
          onCancel={handleCloseModal}
          onSubmit={handleCloseModalNow}
          mobileView={mobileView}
        />
      )}
    </div>
  );
}
