import React, { memo, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes, { array } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useLoadMemberPodsByContribution } from 'components/MemberPods';
import { StyledSlider, StyledInput, Switch } from 'components/switch/style';
import useAccount from 'hooks/useAccount';
import { useMediaQuery, useTheme } from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP, ROUTES } from '../../../../../src/constants';
import {
  fetchClientContribution,
  fetchCohealerContribution,
  setSelectedSessionAndSessionTimeId,
  setSelectedSessionId,
  setSelectedSessionTimeOrContentId,
  setSessionId as setSessionCode,
  setShowMobileDetailedView,
} from 'actions/contributions';
import Session, { SessionRedesign } from './Session';
import ProgressBar from '@ramonak/react-progress-bar';
import axiosInstance from 'utils/axiosInstance';
import { useHttp, useClientPreview, useRouter, useScreenView, useChangedProps } from 'hooks';
import find from 'lodash/find';
import Modal from 'components/UI/Modal';
// import { Grid } from 'antd';
import * as S from '../../../ContributionView/components/ContributionHeader/styled';
import { Grid, Box, ButtonBase } from '@material-ui/core';
import Button from '@mui/material/Button';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType, UserRoles } from 'helpers/constants';
import { ColorSchemeService } from 'services/color.service';
import { determineColorToUse, getThemedColors, shareViaEmail } from 'services/contributions.service';
import { useParams } from 'react-router-dom';
import { lightOrDark } from 'utils/utils';
import { downloadFile, CanClinetInviteFrieds } from 'utils/utils';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import TemplateThree from 'pages/ContributionView/Templates/TemplateThree';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ShareModalContainer from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import CourseSessionRedesign from './CourseSessionRedesign';
import { FILTERTYPES, FILTERS, SESSION_TYPES, SELFPACED_CONTENT_TYPES, DRAGGABLE_TYPES } from '../constants';
import { FilteredSessions, pickContentItemCard, pickContentItemCardList, pickSessionCard } from '../helpers';
import {
  StyledMainContainer,
  TabsRow,
  ContentRow,
  FilterTabsContainer,
  SelfPacedEngagementDataButton,
  FilterTabButton,
} from '../component/styled';
import { LeftSidebarColumn, RightSidebarColumn, MainContentColumn } from '../component/columns';
import MiniDrawer from '../component/RightMiniDrawer';
import { NotesPanel } from 'components/UI/VideoChat/VideoChat/sidePanels';
import {
  LiveSessionExpansionCard,
  LiveSessionTimeCard,
  SelfPacedDocumentContentCard,
  SelfPacedModuleExpansionCard,
  SelfPacedMusicContentCard,
  SelfPacedQuizContentCard,
  SelfPacedVideoContentCard,
} from '../component/cards';
import SessionHeader from '../component/SessionHeader';
import SessionBody from '../component/SessionBody';
import SideBarNotePanel from '../component/NotesPanel/SideBarNotePanel';
import ContributionSessionsProgressBar from '../component/ContributionSessionsProgressBar';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ErrorBoundary from 'components/ErrorBoundary';
import { cloneDeep, debounce, isArray, isEqual } from 'lodash';
import Loader from 'components/UI/Loader';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { updateContributionActions } from 'actions/contributions';
import { VerticalTimeline, VerticalTimelineItem } from '../component/VerticalTimeline';
import { memberPodsService } from 'services/memberPods/memberPods.service';
import { memberPodsSelector } from 'selectors/MemberPods';
import { useContribution } from 'pages/ContributionView/hooks';
import { changeCalendarView } from 'services/communicationTab.service ';
import * as _ from 'lodash';
import {
  activeContribSessTabViewSettingsSelector,
  getActiveContribFilteredSessions,
  getActiveContribSessionById,
} from 'selectors/contribution';
import { colors } from 'utils/styles';

const StickyPortion = styled.div`
  ${({ isSticky, top, themedCardBackgroundColor }) =>
    isSticky &&
    css`
      position: sticky;
      top: ${top};
      z-index: 19;
      background-color: ${themedCardBackgroundColor};
    `}
`;

const StyledSessiosContainer = styled.div`
  width: 100%;
  overflow: hidden;

  ${({ clientPreviewMode }) =>
    clientPreviewMode &&
    css`
      pointer-events: none;
    `}
`;

const StyledTab = styled.div`
  margin-right: 20px;
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  padding: 5px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  margin-left: ${({ marginLeft }) => marginLeft};
`;

const mainStyleOverrides = css`
  &.sessions-timeline.ant-timeline {
    > .ant-timeline-item {
      > .ant-timeline-item-tail {
        color: transparent;
        border-left-color: transparent;
        border-left-width: none;
        border-left: none;
      }
      > .ant-timeline-item-head.ant-timeline-item-head-custom {
        background-color: transparent;
        top: 13px;
        svg.hollow circle {
          fill: transparent;
        }
      }
      > .ant-timeline-item-content {
        margin: 0;
        margin-left: 1rem;
        top: 0;
      }
    }
  }
`;

const SessionsContainer = props => {
  const { children, handleDragEnd, sidebarFilteredSessions, expandedSessionsMap } = props;
  const { isCoach, isClient } = useAccount();
  const sessionToBeExpanded = useSelector(state => state.contributions.sessionId);
  const memoizedChildren = useMemo(() => children, [children]);
  if (isCoach)
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-sessions-list" type={DRAGGABLE_TYPES.SESSION} isDropDisabled={!isCoach}>
          {(dropProvided, dropSnapshot) => {
            return (
              <div
                className="session-group-container"
                ref={dropProvided.innerRef}
                {...dropProvided.droppableProps}
                // style={{ width: '100%', minHeight: 200, overflow: 'hidden' }}
                style={
                  {
                    // background:
                    //   dropSnapshot.isDraggingOver && dropSnapshot.draggingOverWith
                    //     ? // && dropSnapshot.droppable.type === 'SESSIONS'
                    //       'lightblue'
                    //     : 'unset',
                    // padding: 8,
                    // minWidth: 300,
                    // minHeight: 200,
                    // margin: 8,
                  }
                }
              >
                {children}
                {dropProvided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  if (isClient)
    return (
      <VerticalTimeline
        className="session-group-container sessions-timeline"
        {...{
          $mainStyleOverrides: mainStyleOverrides,
        }}
      >
        {/* <VerticalTimelineItem isCompleted hideTail>step1 2015-09-01</VerticalTimelineItem>
    <VerticalTimelineItem isCompleted>step2 2015-09-01</VerticalTimelineItem>
    <VerticalTimelineItem isLocked>step3 2015-09-01</VerticalTimelineItem>
    <VerticalTimelineItem>step4 2015-09-01</VerticalTimelineItem> */}
        {children}
      </VerticalTimeline>
    );
  return <></>;
};
const SessionItem = memo(props => {
  const {
    // s,
    sessionId,
    sessionToBeExpanded,
    i,
    expandedSessionsMap,
  } = props;
  const { isCoach } = useAccount();
  const { isPrerecorded } = useSelector(s => getActiveContribSessionById(s, sessionId) || {});
  const { selectedSessionId } = useSelector(activeContribSessTabViewSettingsSelector);
  // const selectSessionTimeOrContentByIdMemo = useCallback(
  //   timeOrContentId => {
  //     batch(() => {
  //       setSelectedSessionId(s?.id);
  //       setSelectedSessionTimeOrContentId(timeOrContentId);
  //       setShowMobileDetailedView(true);
  //     });
  //   },
  //   [s?.id],
  // );
  useEffect(() => {
    if (expandedSessionsMap?.current) expandedSessionsMap.current.set(sessionId, sessionId === selectedSessionId);
  }, [selectedSessionId]);
  const onExpansion = useCallback(
    (opened = false) => {
      if (expandedSessionsMap?.current) expandedSessionsMap.current.set(sessionId, opened);
    },
    [expandedSessionsMap?.current, sessionId],
  );
  const properties = useMemo(() => {
    return {
      // focusedSession: sessionId || sessionToBeExpanded,
      focusedSession: sessionToBeExpanded,
      // key: s.id,
      number: i,
      // session: s,
      sessionId,
      // selectSessionTimeOrContentById: selectSessionTimeOrContentByIdMemo,
      // selectSessionTimeOrContentById: () => {},
      // dragHandleProps: dragProvided.dragHandleProps,
      // dragProps: { dragProvided, dragSnapshot },
      // ...(isCoach && { dragProps: { dragProvided, dragSnapshot } }),
      isExpanded:
        (expandedSessionsMap?.current && expandedSessionsMap.current?.get(sessionId)) ||
        selectedSessionId === sessionId ||
        false,
      // isExpanded: (expandedSessionsMap.current && expandedSessionsMap.current?.get(sessionId)) || false,
      // onExpansion,
    };
  }, [i, sessionToBeExpanded, expandedSessionsMap?.current, sessionId, onExpansion]);
  const Return = useMemo(
    () =>
      isCoach ? (
        <Draggable
          key={sessionId}
          draggableId={`draggable-${sessionId}`}
          index={i}
          isDragDisabled={!isCoach || !isPrerecorded}
          // isDragDisabled={!s?.isPrerecorded}
        >
          {(dragProvided, dragSnapshot) => {
            return <SessionRedesign {...{ ...properties, dragProvided, dragSnapshot }} />;
          }}
        </Draggable>
      ) : (
        <SessionRedesign {...{ ...properties, key: sessionId }} />
      ),
    [isCoach, isPrerecorded, properties],
  );

  return Return;
}, isEqual);
const SessionsTabLayout = props => {
  const { children, leftCol = <></>, centerCol = <></>, rightCol = <></> } = props;
  const { mobileView } = useScreenView();
  const { showMobileDetailedView: isSelectedToView } = useSelector(activeContribSessTabViewSettingsSelector);
  const [inDetailsMode, setDetailsMode] = useState(isSelectedToView);
  const leftPrev = useRef(null);
  const centerPrev = useRef(null);
  const rightPrev = useRef(null);

  // if (children.length !== 3) {
  //   return null;
  // }
  const [left, center, right] = children || [];
  useEffect(() => {
    setDetailsMode(isSelectedToView);
  }, [isSelectedToView]);

  const leftMemo = useMemo(() => leftCol, [leftCol]);
  const centerMemo = useMemo(() => centerCol, [centerCol]);
  const rightMemo = useMemo(() => rightCol, [rightCol]);

  if (mobileView) {
    if (inDetailsMode) {
      return (
        <>
          {centerCol}
          {rightCol}
        </>
      );
    }
    return <>{leftCol}</>;
  }
  return (
    <>
      {leftCol}
      {centerCol}
      {rightCol}
    </>
  );
};
const StyledText = styled.div`
  color: ${({ textColor }) => textColor || '#000'};
`;

const handleCardClick = () => {};

const sortByTime = session => {
  // Extract the start time of the first sessionTime for each session
  const time = moment(session.endTime);
  return time.unix();
};
const LeftSidebarContainer = memo(props => {
  const {
    // memoizedLeftColHead,
    percentageCompleted,
    columnThemeProps,
    useInternalLeftColumnScroll,
    handleDragEnd,
    // memoizedItems,
    mainBorderColor,
    filter,
    sidebarFilteredSessions,
    sessionToBeExpanded,
    expandedSessionsMap,
  } = props;
  const { mobileView } = useScreenView();
  const memoThemeProps = useMemo(() => columnThemeProps, [
    ...Object.keys(columnThemeProps).map(k => columnThemeProps[k]),
  ]);
  const memoizedLeftColHead = useMemo(() => {
    return <ContributionSessionsProgressBar percentageCompleted={percentageCompleted} />;
  }, [percentageCompleted]);
  const memoizedItems = useMemo(() => {
    return (
      sidebarFilteredSessions
        // .filter(k => k.id === 'ce740dd5-313e-4975-86b8-71b46c9ae97d')
        .map((s, i) => {
          return (
            <SessionItem
              {...{
                // s,
                // sessionId,
                sessionToBeExpanded,
                i,
                sessionId: s?.id,

                expandedSessionsMap,
              }}
            />
          );
        })
    );
  }, [sidebarFilteredSessions, sessionToBeExpanded, expandedSessionsMap]);
  return (
    <LeftSidebarColumn
      head={memoizedLeftColHead}
      {...{
        // ...columnThemeProps,
        ...memoThemeProps,
        headBorderColor: mainBorderColor,
        mobileView,
        useInternalLeftColumnScroll,
        filter,
      }}
    >
      <SessionsContainer {...{ handleDragEnd }}>{memoizedItems}</SessionsContainer>
    </LeftSidebarColumn>
  );
}, isEqual);
const CourseSessions = ({
  changeShowPopUp,
  renderSessionTimeActions,
  // layout: { heightOffset, columnsContainerRef },
}) => {
  const contribution = useContribution();
  const {
    sessions,
    id: contributionId,
    isPurchased,
    timeZoneId,
    percentageCompleted,
    isDarkModeEnabled,
  } = contribution;
  const { selectedSessionId, selectedSessionTimeOrContentId, loading: sessionSwitchLoading } = useSelector(
    activeContribSessTabViewSettingsSelector,
  );
  const { clientPreviewMode } = useClientPreview();
  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const [showCalendarTab, setShowCalendarTab] = useState(contribution?.isCalendarHidden);
  const { user, currentRole, isClient } = useAccount();
  const stickH = useSelector(state => state.stickyHeader?.stickyHeader);
  const { sessionId, contentId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sessionIdToday, setSessionId] = useState(null);
  const [todaySessionAndContent, setTodaySessionAndContent] = useState({ sessionId: null, contentId: null });
  const [podsArray, setPodsArray] = useState([]);
  const { request } = useHttp();
  const isCoach = currentRole == UserRoles.cohealer && !clientPreviewMode;
  const theme = useTheme();
  const { query } = useRouter();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveSession } = useSaveContribution(isEditing);
  const {
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    newThemedCardColor,
    newThemedBackgroundColor,
    themedColor,
    themedCardOutlineColor,
    newThemedTextColor,
  } = getThemedColors(contribution);
  const themedCardBackgroundColor = isCoach ? oldThemedCardBackgroundColor : newThemedCardColor;
  const isUpcomingSession = find(sessions, { isCompleted: false });
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('lg'));
  const mdView = useMediaQuery(theme.breakpoints.down('md'));
  // console.info('view (mobile, tablet, md):', mobileView, tabletView, mdView);
  const sessionToBeExpanded = useSelector(state => state.contributions.sessionId);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { pathname } = useRouter();
  const [loading, setLoading] = useState(false);
  const { contributionMemberPods } = useSelector(memberPodsSelector);
  const scrollToSessionId = contentId || sessionId || location?.state?.sessionId || window?.history?.state?.sessionId;
  const devModeSessionsTab = true; // pathname?.includes('/sessions/dev') || false;
  const useInternalLeftColumnScroll = true;
  const mounted = useRef(false);
  useEffect(() => {
    memberPodsService.getContributionPods(contribution.id).then(data => {
      if (!isEqual(podsArray, data)) setPodsArray(data);
    });
    // filterSessions();
  }, []);

  useEffect(() => {
    let t;
    if (
      contentId ||
      sessionId ||
      location?.state?.sessionId ||
      (window?.history?.state?.sessionId && sessionToBeExpanded === null)
      // || (!isClient && (selectedSessionTimeOrContentId || selectedSessionId))
    ) {
      const idToScroll = contentId || sessionId || location?.state?.sessionId || window?.history.state?.sessionId;
      // || selectedSessionTimeOrContentId
      // || selectedSessionId;
      if (contentId && sessionId) {
        // batch(() => {
        // dispatch(setSelectedSessionId(sessionId));
        // dispatch(setSelectedSessionTimeOrContentId(contentId));
        // });
        dispatch(setSelectedSessionAndSessionTimeId(sessionId, contentId));
      }
      t = setTimeout(() => {
        const element = document.getElementById(idToScroll);
        if (element) {
          // alert(1);
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 3000);
    } else if (sessionIdToday && !!currentRole /* && !selectedSessionTimeOrContentId && !selectedSessionId*/) {
      // const idToScroll =
      //   (todaySessionAndContent?.contentId && todaySessionAndContent?.sessionId
      //     ? todaySessionAndContent?.contentId
      //     : sessionIdToday?.id) || null;
      // if (idToScroll) {
      //   t = setTimeout(() => {
      //     if (todaySessionAndContent?.contentId && todaySessionAndContent?.sessionId) {
      //       // setSelectedSessionId(todaySessionAndContent?.sessionId);
      //       // setSelectedSessionTimeOrContentId(todaySessionAndContent?.contentId);
      //       const element = document.getElementById(todaySessionAndContent?.contentId);
      //       console.info('container-element-2.1:', element);
      //       if (element) {
      //         element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //       }
      //     } else {
      //       const element = document.getElementById(sessionIdToday.id);
      //       console.info('container-element-2.2:', element);
      //       if (element) {
      //         element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //       }
      //     }
      //   }, 3000);
      // }
    }
    // return () => t && clearTimeout(t);
  }, [
    sessionId,
    sessionIdToday,
    sessionToBeExpanded,
    // todaySessionAndContent
  ]);

  useEffect(() => {
    let t1, t2;
    if (sessionToBeExpanded) {
      t2 = setTimeout(() => {
        const element = document.getElementById(sessionToBeExpanded);
        if (element) {
          // alert(2);
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 3000);
      t2 = setTimeout(() => {
        dispatch(setSessionCode(null));
      }, 5000);
    }
    // return () => {
    //   t1 && clearTimeout(t1);
    //   t2 && clearTimeout(t2);
    // };
  }, [sessionToBeExpanded]);

  const onShareButtonClick = () => {
    request(`/Contribution/DownloadSelfpacedModuleDetails?contributionId=${contributionId}`, 'POST', null, {}, 'blob')
      .then(res => downloadFile(res, 'Self-paced Engagement Data.csv'))
      .catch(err => {
        setConnectStripeModal(true);
      });
  };
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const type = contribution?.type;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;

  const [scrollPosition, setScrollPosition] = useState(0);
  const isHeaderWidthEnough = useMediaQuery('(max-width: 1570px)');
  const getTop = type => {
    let t;
    switch (type) {
      case TemplateType.TemplateThree:
        t = isCoach ? 'calc(150px + 17px)' : '144px';
        break;
      case TemplateType.TemplateOne:
        t = isCoach ? '104px' : '112px';
        break;
      case TemplateType.TemplateTwo:
        t = isCoach ? 'calc(94px + 20px)' : isHeaderWidthEnough ? 'calc(94px + 54px)' : 'calc(94px + 21px)';
        break;
      default:
        t = '139px';
    }

    return t;
  };
  const btnColor = colorToUse?.PrimaryColorCode;
  const progressbarColor = colorToUse?.AccentColorCode;

  const TABS = ['Live', 'Self-Paced'];
  const [activeTab, setActiveTab] = useState('Live');
  const filters = ['All', 'Upcoming', 'Live', 'SelfPaced', 'Past'];
  // const [filter, setFilter] = useState(scrollToSessionId ? FILTERTYPES.ALL : FILTERTYPES.UPCOMING);
  const [filter, setFilter] = useState(FILTERTYPES.ALL);

  const [filterClicked, setFilterClicked] = useState(false);
  const expandedSessionsMap = useRef(new Map());
  // useEffect(() => {
  //   setLoading(true);
  //   setLoading(false);
  // }, [filter, filterClicked]);
  useEffect(() => {
    setLoading(true);

    setLoading(false);
  }, [filter]);

  const convertedToSelfPaced = useCallback(() => {
    if (filter != filters[0]) {
      setFilter(filters[0]);
      setFilterClicked(true);
    }
  }, [filter]);

  const isTodaySession = session => {
    return session.sessionTimes.some(
      st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') == 0,
    );
  };

  const liveSessions = sessions => {
    const live = sessions?.filter(s => !s.isPrerecorded && !s.isCompleted);
    return live;
  };

  const sessionsHappenningToday = sessions => {
    const todaySessions = sessions?.filter(s => isTodaySession(s) && !s.isPrerecorded && !s.isCompleted);
    return todaySessions || [];
  };

  const sessionsHappenningNotToday = sessions => {
    const notTodaySessions = sessions?.filter(s => !isTodaySession(s) || s.isPrerecorded);
    return notTodaySessions || [];
  };

  const splitAndSortSessions = sessions => {
    const happeningToday = sessionsHappenningToday(sessions);
    const notHappeningToday = sessionsHappenningNotToday(sessions);
    const happeningTodaySorted = !!happeningToday ? orderBy(happeningToday, ['startTime'], ['asc']) : happeningToday;
    const notHappeningTodaySorted = !!notHappeningToday
      ? orderBy(notHappeningToday, ['startTime'], ['asc'])
      : notHappeningToday;
    const mergedSessions = [].concat(happeningTodaySorted, notHappeningTodaySorted);
    return mergedSessions;
  };
  const FilteredSessionsCount = (sessions, type) => {
    if (isEmpty(user) || contribution.isPurchased === false) {
      if (type == FILTERTYPES.ALL) {
        return sessions.length;
      }
      if (type == FILTERTYPES.LIVE) {
        return sessions.filter(s => !s.isPrerecorded).length;
      } else if (type == FILTERTYPES.SELFPACED) {
        return sessions.filter(s => s.isPrerecorded).length;
      } else if (type == FILTERTYPES.PAST) {
        if (isCoach) {
          return sessions.filter(s => !s.isPrerecorded && s.isCompleted).length;
        } else {
          return sessions.filter(s => s.isCompleted).length;
        }
      } else if (type == FILTERTYPES.UPCOMING) {
        if (isCoach) {
          const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
          const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
          const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For coach all those sessions which are not completed will be considered as upcoming sessions
          const allSelfpacedSessionsUpcoming = allSelfpacedSessions; // For coach all self paced module will be considered as upcoming sessions bcz coach have to authority to complete the selfpaced modules
          const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
          return AllUpcomingSessions.length || [].length;
        } else {
          const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
          const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
          const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For client all those sessions which are not completed will be considered as upcoming sessions
          const allSelfpacedSessionsUpcoming = allSelfpacedSessions.filter(s => !s.isCompleted); // For client all those self paced modules which are not completed will be considered as upcoming modules
          const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
          return AllUpcomingSessions.length || [].length;
        }
      } else {
        return sessions.length;
      }
    }
    if (type == FILTERTYPES.ALL) {
      const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded).length;
      if (filterSessions() === 0 && allSelfpacedSessions === 0) {
        return [].length;
      } else if (allSelfpacedSessions > 0 && filterSessions() === 0) {
        return allSelfpacedSessions.length || [].length;
      } else {
        return filterSessions();
      }
    }
    if (type == FILTERTYPES.LIVE) {
      if (filterSessions() === 0) {
        return 0;
      } else {
        return filterSessions() - sessions.filter(s => s.isPrerecorded).length;
      }
    } else if (type == FILTERTYPES.SELFPACED) {
      return sessions.filter(s => s.isPrerecorded).length;
    } else if (type == FILTERTYPES.PAST) {
      if (isCoach) {
        return sessions.filter(s => !s.isPrerecorded && s.isCompleted).length;
      } else {
        return sessions.filter(s => s.isCompleted).length;
      }
    } else if (type == FILTERTYPES.UPCOMING) {
      if (isCoach) {
        const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
        const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
        const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For coach all those sessions which are not completed will be considered as upcoming sessions
        const allSelfpacedSessionsUpcoming = allSelfpacedSessions; // For coach all self paced module will be considered as upcoming sessions bcz coach have to authority to complete the selfpaced modules
        const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
        return AllUpcomingSessions.length || [].length;
      } else {
        const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
        const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
        const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For client all those sessions which are not completed will be considered as upcoming sessions
        const allSelfpacedSessionsUpcoming = allSelfpacedSessions.filter(s => !s.isCompleted); // For client all those self paced modules which are not completed will be considered as upcoming modules
        const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
        if (filterSessions() === 0 && allSelfpacedSessions.length === 0) {
          return [].length;
        } else {
          return filterSessions() - sessions.filter(s => !s.isPrerecorded && s.isCompleted).length || [].length;
        }
      }
    } else {
      return filterSessions();
    }
  };
  // const propsForLeftSidebarSession = _.concat(['id', 'name', 'isPrerecorded'], isClient && ['isCompleted']);
  const propsForLeftSidebarSession = ['id'];
  const propsForLeftSidebarSessionTime = [
    'startTime',
    'ignoreDateAvailable',
    'usersWhoViewedRecording',
    'id',
    'isCompleted',
    'subCategoryName',
    'completedSelfPacedParticipantIds',
    'embeddedVideoUrl',
    'prerecordedSession',
  ];
  const filteredSessionsByFilter = useMemo(() => {
    return FilteredSessions(contribution?.sessions, filter, isCoach);
  }, [contribution?.sessions, filter, isCoach]);
  const podsArrayMemo = useMemo(() => podsArray || [], [podsArray]);
  const filteredSessions = useMemo(() => {
    // if (
    //   // !podsArrayMemo
    //   !podsArray
    // ) {
    //   return;
    // }
    // setShowMobileDetailedView(false);
    // return filteredSessionsByFilter;
    return filteredSessionsByFilter.filter(k => {
      return (
        isCoach ||
        k?.sessionTimes?.some(st => {
          const pod = podsArrayMemo?.find(p => p.id === st.podId);
          return st?.podId === '' || st?.podId === null || isEmpty(pod) || pod?.clientIds?.includes(user?.id);
        })
      );
    });
    return orderBy(
      FilteredSessions(sessions, filter, isCoach),
      [filter === FILTERTYPES.PAST ? 'completedDateTime' : 'startTime'],
      [filter === FILTERTYPES.PAST ? 'desc' : 'asc'],
    );
  }, [filteredSessionsByFilter, isCoach, podsArrayMemo, user?.id]);
  // const filteredSessions = useSelector(s =>
  //   getActiveContribFilteredSessions(
  //     s,
  //     filter,
  //     // podsArrayMemo
  //   ),
  // );
  // const filteredSessionsCount = useMemo(() => filteredSessions?.length, [filteredSessions?.length]);
  const filteredSessionsCount = filteredSessions?.length;
  const isLastSessionOrTimeContent = (sessionTimes = [], id) => {
    const currentIndex = sessionTimes?.findIndex(obj => obj.id === id);
    return currentIndex === sessionTimes.length - 1;
  };

  const nextSessionTimeNotCompleted = (contents = [], id = null) => {
    if (!id) {
      return;
    }
    const currentIndex = contents.findIndex(obj => obj.id === id);
    const remainingContents = contents.slice(currentIndex + 1);
    const incompleteSession = remainingContents.find(obj => {
      return !obj.isCompleted && !obj?.completedSelfPacedParticipantIds?.includes(user?.id);
    });
    return incompleteSession ? incompleteSession.id : null;
  };
  const nextSessionTimeofNextSession = (modules = [], id = null) => {
    if (!id) {
      return;
    }
    const currentIndex = modules.findIndex(obj => obj.id === id);
    for (let i = currentIndex + 1; i < modules.length; i++) {
      const nextSession = modules[i];
      const inCompleteSessionTime = nextSession?.sessionTimes?.find(obj => {
        return !obj.isCompleted && !obj?.completedSelfPacedParticipantIds?.includes(user?.id);
      });
      if (inCompleteSessionTime) {
        return { sessionId: nextSession?.id, sessionTimeId: inCompleteSessionTime?.id };
      }
    }
    return null;
  };
  const getFirstIncompleteModuleContent = (modules = []) => {
    for (const mod of modules) {
      const inCompleteSessionTime = mod?.sessionTimes?.find(obj => {
        return !obj.isCompleted && !obj?.completedSelfPacedParticipantIds?.includes(user?.id);
      });
      if (inCompleteSessionTime) {
        return { sessionId: mod?.id, sessionTimeId: inCompleteSessionTime?.id };
      }
    }
    return null;
  };
  const updateSelected = (sessionId, sessionTimeId) => {
    // batch(() => {
    // dispatch(setSelectedSessionTimeOrContentId(sessionTimeId));
    // dispatch(setSelectedSessionId(sessionId));
    // });
    dispatch(setSelectedSessionAndSessionTimeId(sessionId, sessionTimeId));
  };
  const selectNextSession = useCallback(() => {
    if (!selectedSessionId || !selectedSessionTimeOrContentId) {
      return;
    }
    const currentModuleContents = filteredSessions.find(obj => obj.id === selectedSessionId)?.sessionTimes || [];
    const nextSessionContentId = nextSessionTimeNotCompleted(currentModuleContents, selectedSessionTimeOrContentId);
    if (nextSessionContentId) {
      return () => {
        updateSelected(selectedSessionId, nextSessionContentId);
      };
    }
    const lastSession = isLastSessionOrTimeContent(filteredSessions, selectedSessionId);
    if (lastSession) {
      const nextFromPreviousOrStart = getFirstIncompleteModuleContent(filteredSessions);
      if (nextFromPreviousOrStart) {
        const { sessionId, sessionTimeId } = nextFromPreviousOrStart;
        return () => {
          updateSelected(sessionId, sessionTimeId);
        };
      }
    }
    const next = nextSessionTimeofNextSession(filteredSessions, selectedSessionId);
    if (next) {
      const { sessionId, sessionTimeId } = next;
      return () => {
        updateSelected(sessionId, sessionTimeId);
      };
    }
    return;
  }, [filteredSessions, selectedSessionId, selectedSessionTimeOrContentId]);
  // const debouncedSelectNextSession = debounce(selectNextSession, 1000);

  useEffect(() => {
    const allLiveUncompletedSessions = liveSessions(sessions);
    const allLiveUncompletedSessionsSorted = !!allLiveUncompletedSessions
      ? orderBy(allLiveUncompletedSessions, ['startTime'], ['asc'])
      : allLiveUncompletedSessions;
    const happeningTodaySessions = sessionsHappenningToday(sessions);
    const notHappeningTodaySessions = sessionsHappenningNotToday(sessions);
    const happeningTodaySessionsSorted = !!happeningTodaySessions
      ? orderBy(happeningTodaySessions, ['startTime'], ['asc'])
      : happeningTodaySessions;
    const notHappeningTodaySessionsSorted = !!notHappeningTodaySessions
      ? orderBy(notHappeningTodaySessions, ['startTime'], ['asc'])
      : notHappeningTodaySessions;
    if (allLiveUncompletedSessions.length > 0) {
      setSessionId(happeningTodaySessionsSorted[0]);
    }
  }, [sessions]);
  useEffect(() => {
    console.info('changed-of:', selectedSessionId, selectedSessionTimeOrContentId);
  }, [selectedSessionId, selectedSessionTimeOrContentId]);
  useLoadMemberPodsByContribution({ contributionId, userId: user.id });
  const [InviteModalOpened, setInviteModalOpened] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const handleModalClose = useCallback(() => {
    setEmailsError({
      isError: false,
      errorMessage: null,
    });
    setInviteModalOpened(false);
  }, [setInviteModalOpened]);
  const handleModalSubmit = useCallback(
    (emails, priceId) => {
      shareViaEmail(
        priceId === null
          ? { contributionId: contribution?.id, emailAddresses: emails }
          : { contributionId: contribution?.id, emailAddresses: emails, priceId: priceId },
      )
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({
            isError: true,
            errorMessage: err?.response?.data?.message,
          });
        });
    },
    [contribution?.id, handleModalClose],
  );
  let countNumber = 0;
  const filterSessions = () => {
    // const podIds = sessions.flatMap(session => session.sessionTimes.filter(sessionTime => sessionTime.podId !== "").map(sessionTime => sessionTime.podId));
    let count = 0;
    console.log('dATA----->', podsArray);
    for (const session of sessions) {
      let shouldCountSession = false;
      for (const sessionTime of session.sessionTimes) {
        // Check if SessionTime does not have a PodId
        if (sessionTime.podId === '' || sessionTime.podId === null) {
          shouldCountSession = true;
          break; // One SessionTime without a PodId is enough to count the session
        } else {
          // If SessionTime has a PodId, check if the user is not in the Pod's ClientIds
          const pod = podsArray.find(p => p.id === sessionTime.podId);
          if (isEmpty(pod) || pod.clientIds.includes(user.id)) {
            shouldCountSession = true;
            break; // User not being in a Pod's ClientIds is enough to count the session
          }
        }
      }

      // If the criteria are met, count the session
      if (shouldCountSession) {
        count = count + 1;
      }
    }
    console.log('dATA----->', count);
    return count;
  };

  const updateContributionSessions = async updatedSessions => {
    const updatedContribution = {
      ...contribution,
      sessions: updatedSessions,
    };
    // console.info('updated sessions:', cloneDeep(updatedSessions));
    dispatch(updateContributionActions.success(updatedContribution));
    await onSaveSession(updatedContribution);
  };
  const handleDragEnd = useCallback(
    result => {
      // // SAMPLE RESULT: sessions moving
      // {
      //   "draggableId": "draggable-8aa17a80-046d-4e06-b790-ae2bbc0e466e",
      //   "type": "SESSIONS",
      //   "source": {
      //     "index": 1,
      //     "droppableId": "droppable-sessions-list"
      //   },
      //   "reason": "DROP",
      //   "mode": "FLUID",
      //   "destination": {
      //     "droppableId": "droppable-sessions-list",
      //     "index": 2
      //   },
      //   "combine": null
      // }

      // // SAMPLE RESULT: session's content moving
      // {
      //   "draggableId": "draggable-23abd133-0acf-467a-8447-a5a9ea2bff04",
      //   "type": "SESSION-CONTENT-ce740dd5-313e-4975-86b8-71b46c9ae97d",
      //   "source": {
      //     "index": 0,
      //     "droppableId": "droppable-ce740dd5-313e-4975-86b8-71b46c9ae97d"
      //   },
      //   "reason": "DROP",
      //   "mode": "FLUID",
      //   "destination": {
      //     "droppableId": "droppable-ce740dd5-313e-4975-86b8-71b46c9ae97d",
      //     "index": 2
      //   },
      //   "combine": null
      // }

      const { type, source, destination, draggableId } = result;

      if (!type || !destination || !source || !draggableId) {
        return;
      }
      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }

      const draggedItemId = draggableId.split('draggable-').at(1);

      if (type === DRAGGABLE_TYPES.SESSION) {
        // const session = sessions.find(s => s.id === draggedItemId);
        // const filteredSessionsIndex = filteredSessions.findIndex(s => s.id === draggedItemId);
        // const filteredSession = filteredSessions.at(filteredSessionsIndex);

        const session = filteredSessions.at(source.index);
        const targetSession = filteredSessions.at(destination.index);
        if (!session || !targetSession) {
          return;
        }

        const newSessions = cloneDeep(sessions);
        // newSessions.splice(source.index, 1);
        // newSessions.splice(destination.index, 0, session);
        const oldSessionIndex = newSessions.findIndex(s => s.id === (session.id || draggedItemId));
        const newTargetSessionIndex = newSessions.findIndex(s => s.id === targetSession.id);

        newSessions.splice(oldSessionIndex, 1);
        newSessions.splice(newTargetSessionIndex, 0, session);

        // TODO: here update the contribution record (redux + backend + etc)
        updateContributionSessions(newSessions);

        // dispatch(updateContribution({ sessions: newSessions }));
      } else if (type.startsWith(DRAGGABLE_TYPES.SESSION_CONTENT)) {
        const dropSessionId = source.droppableId.split('droppable-').at(1);
        // const sessionContent = sessions
        //   .map(s => s.sessionTimes)
        //   .flat()
        //   .find(st => st.id === draggedItemId);
        const newSessions = cloneDeep(sessions);
        const sessionIndex = newSessions.findIndex(s => s.id === dropSessionId);
        const session = newSessions.at(sessionIndex);
        if (!session) {
          return;
        }
        const newSessionTimes = cloneDeep(session.sessionTimes || []);
        const sessionContent = newSessionTimes.find(st => st.id === draggedItemId);
        if (!sessionContent) {
          return;
        }
        newSessionTimes.splice(source.index, 1);
        newSessionTimes.splice(destination.index, 0, sessionContent);
        newSessions[sessionIndex] = { ...session, sessionTimes: newSessionTimes };

        // TODO: here update the contribution record (redux + backend + etc)
        updateContributionSessions(newSessions);

        // dispatch(updateContribution({ sessions: newSessions }));
      }
    },
    [sessions, filteredSessions],
  );

  useEffect(() => {
    if (isArray(filteredSessions) && filteredSessions.length && !mobileView) {
      // if (!selectedSessionId && !selectedSessionTimeOrContentId) {

      if ([FILTERTYPES.ALL, FILTERTYPES.LIVE, FILTERTYPES.UPCOMING].includes(filter)) {
        const allLiveUncompletedSessions = liveSessions(filteredSessions);
        // const allLiveUncompletedSessionsSorted = !!allLiveUncompletedSessions
        //   ? orderBy(allLiveUncompletedSessions, 'startTime', 'desc')
        //   : // _.map(allLiveUncompletedSessions, session => {
        //     //   // Sort sessionTimes based on startTime
        //     //   const sortedSessionTimes = _.sortBy(session.sessionTimes, st => moment(st.startTime));

        //     //   // Return the session object with sorted sessionTimes
        //     //   return { ...session, sessionTimes: sortedSessionTimes };
        //     // })
        //     allLiveUncompletedSessions;
        // console.info('sessions-1:', cloneDeep(allLiveUncompletedSessions), cloneDeep(allLiveUncompletedSessionsSorted));
        // const todayOrNextUpcomingSession = allLiveUncompletedSessionsSorted?.find(k =>
        //   orderBy(k.sessionTimes || [], ['startTime'], ['asc'])
        //     // ?.sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'))
        //     .some(
        //       st =>
        //         moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') === 0 ||
        //         (moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 1 &&
        //           moment(st.startTime).isAfter(moment(), 'm')),
        //     ),
        // );
        // console.info(
        //   'upcoming-session:',
        //   cloneDeep(todayOrNextUpcomingSession),
        //   cloneDeep(todayOrNextUpcomingSession.sessionTimes),
        //   cloneDeep(todayOrNextUpcomingSession.sessionTimes?.sort((a, b) => moment(a).diff(moment(b), 'minutes'))),
        // );
        // if (todayOrNextUpcomingSession) {
        //   const sId = todayOrNextUpcomingSession?.id;
        //   const cId = todayOrNextUpcomingSession?.sessionTimes
        //     // ?.sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'))
        //     // ?.find(
        //     //   st =>
        //     //     moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 0 &&
        //     //     moment(st.startTime).isAfter(moment(), 'm'),
        //     // )
        //     ?.find(
        //       st =>
        //         moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') === 0 ||
        //         (moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 1 &&
        //           moment(st.startTime).isAfter(moment(), 'm')),
        //     )?.id;
        //   console.info('today-session-ids:', sId, cId);

        //   batch(() => {
        //     setSelectedSessionId(sId);
        //     setSelectedSessionTimeOrContentId(cId);
        //   });
        //   mounted.current = true;

        //   // setTodaySessionAndContent({
        //   //   sessionId: sId,
        //   //   contentId: cId,
        //   // });
        //   return;
        // }
        const flatSessionTimes = _.flatten((allLiveUncompletedSessions || []).map(k => k.sessionTimes));
        // const ordered = _.orderBy(flatSessionTimes, ['sessionTime'], ['asc']) || [];
        const ordered =
          _.orderBy(
            flatSessionTimes?.filter(k => !k.isCompleted),
            sortByTime,
            'asc',
          ) || [];

        // Get the current time
        const currentTime = moment();

        // Find the next session time
        let nextSessionTime = null;
        let relevantSessionId = null;

        for (let i = 0; i < ordered.length; i++) {
          const sessionTime = ordered[i];
          const sessionStartTime = moment(sessionTime?.endTime);

          // Compare the session start time with the current time
          if (sessionStartTime.isAfter(currentTime)) {
            nextSessionTime = sessionTime?.id;
            relevantSessionId = allLiveUncompletedSessions.find(session =>
              session.sessionTimes?.some(st => st.id === sessionTime?.id),
            )?.id;
            break;
          }
        }
        if (nextSessionTime && relevantSessionId) {
          const sId = relevantSessionId;
          const cId = nextSessionTime;

          console.info('today-session-ids:', sId, cId);

          // batch(() => {
          // dispatch(setSelectedSessionId(sId));
          // dispatch(setSelectedSessionTimeOrContentId(cId));
          // });
          dispatch(setSelectedSessionAndSessionTimeId(sId, cId));
          mounted.current = true;

          // setTodaySessionAndContent({
          //   sessionId: sId,
          //   contentId: cId,
          // });
          return;
        }

        // console.info(
        //   'session:today:',
        //   happeningTodaySessionsSorted,
        //   happeningTodaySessionsSorted?.length,
        //   cloneDeep(happeningTodaySessionsSorted?.[0]),
        // );
        // console.info(
        //   'session:upcoming:',
        //   upcomingSessionsAfterToday,
        //   upcomingSessionsAfterToday?.length,
        //   cloneDeep(upcomingSessionsAfterToday?.[0]),
        // );

        // if (happeningTodaySessionsSorted?.length > 0) {
        //   const sId = happeningTodaySessionsSorted?.[0]?.id;
        //   const cId = happeningTodaySessionsSorted?.[0]?.sessionTimes?.find(
        //     st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') == 0,
        //   )?.id;
        //   console.info('today-session-ids:', sId, cId);

        //   batch(() => {
        //     setSelectedSessionId(sId);
        //     setSelectedSessionTimeOrContentId(cId);
        //   });
        //   mounted.current = true;

        //   // setTodaySessionAndContent({
        //   //   sessionId: sId,
        //   //   contentId: cId,
        //   // });
        //   return;
        // } else if (upcomingSessionsAfterToday?.length > 0) {
        //   const sId = upcomingSessionsAfterToday?.[0]?.id;
        //   const cId = upcomingSessionsAfterToday?.[0]?.sessionTimes?.find(
        //     st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 1,
        //   )?.id;
        //   console.info('upcoming-session-ids:', sId, cId);

        //   batch(() => {
        //     setSelectedSessionId(sId);
        //     setSelectedSessionTimeOrContentId(cId);
        //   });
        //   mounted.current = true;

        //   // setTodaySessionAndContent({
        //   //   sessionId: sId,
        //   //   contentId: cId,
        //   // });
        //   return;
        // }
      }
      // batch(() => {
      // Note: find from selected 'filter', the above exist or get the 1st element from new list
      const firstFilteredSession =
        selectedSessionId && filteredSessions?.length > 0 && filteredSessions.some(k => k.id === selectedSessionId)
          ? filteredSessions.find(k => k.id === selectedSessionId)
          : filteredSessions.at(0);
      if (firstFilteredSession) {
        // dispatch(setSelectedSessionId(firstFilteredSession?.id));
        const firstFilteredSessionContent =
          selectedSessionTimeOrContentId &&
          firstFilteredSession?.sessionTimes?.length > 0 &&
          firstFilteredSession?.sessionTimes?.some(k => k.id === selectedSessionTimeOrContentId)
            ? firstFilteredSession?.sessionTimes?.find(k => k.id === selectedSessionTimeOrContentId)
            : firstFilteredSession?.sessionTimes?.at(0);
        if (firstFilteredSessionContent) {
          // dispatch(setSelectedSessionTimeOrContentId(firstFilteredSessionContent?.id));
          dispatch(setSelectedSessionAndSessionTimeId(firstFilteredSession?.id, firstFilteredSessionContent?.id));
        }
      }
      // });
      mounted.current = true;
      // }
    }
  }, []);
  useEffect(() => {
    // // not mounted
    // if (!mounted.current) {
    //   batch(() => {
    //     // dispatch(setSelectedSessionId(null));
    //     // dispatch(setSelectedSessionTimeOrContentId(null));
    //     dispatch(setSelectedSessionAndSessionTimeId(null, null));
    //     // setShowMobileDetailedView(false);
    //   });
    //   return;
    // }

    if (isArray(filteredSessions) && filteredSessions.length) {
      // if (!mobileView) {
      // if (!selectedSessionId && !selectedSessionTimeOrContentId) {

      if (
        // !isEmpty(selectedSessionId) &&
        // !isEmpty(selectedSessionTimeOrContentId) &&
        // selectedSessionId && selectedSessionTimeOrContentId &&
        [FILTERTYPES.ALL, FILTERTYPES.LIVE, FILTERTYPES.UPCOMING].includes(filter)
      ) {
        const allLiveUncompletedSessions = liveSessions(filteredSessions);
        // const allLiveUncompletedSessionsSorted = !!allLiveUncompletedSessions
        //   ? orderBy(allLiveUncompletedSessions, 'startTime', 'desc')
        //   : // _.map(allLiveUncompletedSessions, session => {
        //     //   // Sort sessionTimes based on startTime
        //     //   const sortedSessionTimes = _.sortBy(session.sessionTimes, st => moment(st.startTime));

        //     //   // Return the session object with sorted sessionTimes
        //     //   return { ...session, sessionTimes: sortedSessionTimes };
        //     // })
        //     allLiveUncompletedSessions;
        // console.info('sessions-1:', cloneDeep(allLiveUncompletedSessions), cloneDeep(allLiveUncompletedSessionsSorted));
        // const todayOrNextUpcomingSession = allLiveUncompletedSessionsSorted?.find(k =>
        //   orderBy(k.sessionTimes || [], ['startTime'], ['asc'])
        //     // ?.sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'))
        //     .some(
        //       st =>
        //         moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') === 0 ||
        //         (moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 1 &&
        //           moment(st.startTime).isAfter(moment(), 'm')),
        //     ),
        // );
        // console.info(
        //   'upcoming-session:',
        //   cloneDeep(todayOrNextUpcomingSession),
        //   cloneDeep(todayOrNextUpcomingSession.sessionTimes),
        //   cloneDeep(todayOrNextUpcomingSession.sessionTimes?.sort((a, b) => moment(a).diff(moment(b), 'minutes'))),
        // );
        // if (todayOrNextUpcomingSession) {
        //   const sId = todayOrNextUpcomingSession?.id;
        //   const cId = todayOrNextUpcomingSession?.sessionTimes
        //     // ?.sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'))
        //     // ?.find(
        //     //   st =>
        //     //     moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 0 &&
        //     //     moment(st.startTime).isAfter(moment(), 'm'),
        //     // )
        //     ?.find(
        //       st =>
        //         moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') === 0 ||
        //         (moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 1 &&
        //           moment(st.startTime).isAfter(moment(), 'm')),
        //     )?.id;
        //   console.info('today-session-ids:', sId, cId);

        //   batch(() => {
        //     setSelectedSessionId(sId);
        //     setSelectedSessionTimeOrContentId(cId);
        //   });
        //   mounted.current = true;

        //   // setTodaySessionAndContent({
        //   //   sessionId: sId,
        //   //   contentId: cId,
        //   // });
        //   return;
        // }
        const flatSessionTimes = _.flatten((allLiveUncompletedSessions || []).map(k => k.sessionTimes));
        // const ordered = _.orderBy(flatSessionTimes, ['sessionTime'], ['asc']) || [];
        const ordered =
          _.orderBy(
            flatSessionTimes?.filter(k => !k.isCompleted),
            sortByTime,
            'asc',
          ) || [];

        // Get the current time
        const currentTime = moment();

        // Find the next session time
        let nextSessionTime = null;
        let relevantSessionId = null;

        for (let i = 0; i < ordered.length; i++) {
          const sessionTime = ordered[i];
          const sessionStartTime = moment(sessionTime?.endTime);

          // Compare the session start time with the current time
          if (sessionStartTime.isAfter(currentTime)) {
            nextSessionTime = sessionTime?.id;
            relevantSessionId = allLiveUncompletedSessions.find(session =>
              session.sessionTimes?.some(st => st.id === sessionTime?.id),
            )?.id;
            break;
          }
        }
        if (nextSessionTime && relevantSessionId) {
          const sId = relevantSessionId;
          const cId = nextSessionTime;

          console.info('today-session-ids:', sId, cId);

          // batch(() => {
          // dispatch(setSelectedSessionId(sId));
          // dispatch(setSelectedSessionTimeOrContentId(cId));
          // });
          if (selectedSessionId !== sId && selectedSessionTimeOrContentId !== cId) {
            dispatch(setSelectedSessionAndSessionTimeId(sId, cId));
          }
          mounted.current = true;

          // setTodaySessionAndContent({
          //   sessionId: sId,
          //   contentId: cId,
          // });
          return;
        }

        // console.info(
        //   'session:today:',
        //   happeningTodaySessionsSorted,
        //   happeningTodaySessionsSorted?.length,
        //   cloneDeep(happeningTodaySessionsSorted?.[0]),
        // );
        // console.info(
        //   'session:upcoming:',
        //   upcomingSessionsAfterToday,
        //   upcomingSessionsAfterToday?.length,
        //   cloneDeep(upcomingSessionsAfterToday?.[0]),
        // );

        // if (happeningTodaySessionsSorted?.length > 0) {
        //   const sId = happeningTodaySessionsSorted?.[0]?.id;
        //   const cId = happeningTodaySessionsSorted?.[0]?.sessionTimes?.find(
        //     st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') == 0,
        //   )?.id;
        //   console.info('today-session-ids:', sId, cId);

        //   batch(() => {
        //     setSelectedSessionId(sId);
        //     setSelectedSessionTimeOrContentId(cId);
        //   });
        //   mounted.current = true;

        //   // setTodaySessionAndContent({
        //   //   sessionId: sId,
        //   //   contentId: cId,
        //   // });
        //   return;
        // } else if (upcomingSessionsAfterToday?.length > 0) {
        //   const sId = upcomingSessionsAfterToday?.[0]?.id;
        //   const cId = upcomingSessionsAfterToday?.[0]?.sessionTimes?.find(
        //     st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') >= 1,
        //   )?.id;
        //   console.info('upcoming-session-ids:', sId, cId);

        //   batch(() => {
        //     setSelectedSessionId(sId);
        //     setSelectedSessionTimeOrContentId(cId);
        //   });
        //   mounted.current = true;

        //   // setTodaySessionAndContent({
        //   //   sessionId: sId,
        //   //   contentId: cId,
        //   // });
        //   return;
        // }
      }
      // batch(() => {
      // Note: find from selected 'filter', the above exist or get the 1st element from new list
      const firstFilteredSession =
        selectedSessionId && filteredSessions.some(k => k.id === selectedSessionId)
          ? filteredSessions.find(k => k.id === selectedSessionId)
          : filteredSessions.at(0);
      if (firstFilteredSession) {
        // dispatch(setSelectedSessionId(firstFilteredSession?.id));
        const firstFilteredSessionContent =
          selectedSessionTimeOrContentId &&
          firstFilteredSession?.sessionTimes?.some(k => k.id === selectedSessionTimeOrContentId)
            ? firstFilteredSession?.sessionTimes?.find(k => k.id === selectedSessionTimeOrContentId)
            : firstFilteredSession?.sessionTimes?.at(0);
        if (firstFilteredSessionContent) {
          if (
            selectedSessionId !== firstFilteredSession?.id &&
            selectedSessionTimeOrContentId !== firstFilteredSessionContent?.id
          ) {
            // dispatch(setSelectedSessionTimeOrContentId(firstFilteredSessionContent?.id));
            dispatch(setSelectedSessionAndSessionTimeId(firstFilteredSession?.id, firstFilteredSessionContent?.id));
          }
        }
      }
      // });
      // }
      // }
    } else {
      // batch(() => {
      // dispatch(setSelectedSessionId(null));
      // dispatch(setSelectedSessionTimeOrContentId(null));
      // setShowMobileDetailedView(false);
      // });
      dispatch(setSelectedSessionAndSessionTimeId(null, null));
    }
  }, [filter]);
  // useEffect(() => {
  //   console.info('called selected:', selectedSessionId, selectedSessionTimeOrContentId);
  //   //   // if (mobileView && filteredSessions && selectedSessionId && selectedSessionTimeOrContentId) {
  //   //   //   console.info('jj:', mobileView, selectedSessionId, selectedSessionTimeOrContentId);
  //   //     setShowMobileDetailedView(false);
  //   //   // }
  // }, [selectedSessionId, selectedSessionTimeOrContentId]);
  // const heightOffsetMemoized = useMemo(() => heightOffset, [heightOffset]);
  // console.log('sessionId-------------->1122', sessionId);
  const columnThemeProps = {
    // TODO: border color for white theme: var(--Cohere-Greys-Grey-Tint, #DFE3E4)
    borderColor: contribution?.isDarkModeEnabled ? themedCardOutlineColor : '#dfe3e4',
    backgroundColor: contribution?.isDarkModeEnabled ? themedCardBackgroundColor : '#fff',
    headBackgroundColor: contribution?.isDarkModeEnabled ? themedCardBackgroundColor : '#fff',
    bodyBackgroundColor: contribution?.isDarkModeEnabled ? themedCardBackgroundColor : '#fff',
    textColor: isCoach ? themedColor : newThemedTextColor,
  };
  const mainBorderColor = contribution?.isDarkModeEnabled ? themedCardOutlineColor : '#dfe3e4';

  // const memoizedLeftColHead = useMemo(
  //   () => <ContributionSessionsProgressBar percentageCompleted={percentageCompleted} />,
  //   [percentageCompleted],
  // );
  const sidebarFilteredSessions = useMemo(() => {
    const l = filteredSessions.map(k => _.pick(k, propsForLeftSidebarSession));
    return l;
  }, [filteredSessions]);

  // if (loading || !selectedSessionId || !selectedSessionTimeOrContentId) {
  //   console.info('render:1');
  //   return <Loader />;
  // }
  // return <>hi there! no one is there!</>;

  return (
    <>
      {(sessionSwitchLoading || loading) && <Loader />}
      {/* <CourseSessionRedesign {...{ progressbarColor, percentageCompleted, sessions }} /> */}
      {devModeSessionsTab && (
        <>
          {
            <>
              <ErrorBoundary>
                <StyledMainContainer
                // {...{ heightOffset }}
                >
                  <TabsRow
                    className="row--tabs"
                    {...{
                      mobileView,
                      isSticky: stickH && !mobileView,
                      top: isCoach
                        ? tabletView
                          ? '220px'
                          : '144px'
                        : tabletView
                        ? `calc(${getTop(activeTemplate)} + 33px)`
                        : getTop(activeTemplate),
                      themedCardBackgroundColor: newThemedBackgroundColor,
                      borderColor: contribution?.isDarkModeEnabled ? themedCardOutlineColor : '#dfe3e4',
                    }}
                  >
                    <>
                      <FilterTabsContainer>
                        {filters.map(item => (
                          <>
                            <FilterTabButton
                              {...{
                                btnColor,
                                textColor: item === filter ? textColor : btnColor,
                                backgroundColor:
                                  item === filter
                                    ? btnColor
                                    : contribution?.isDarkModeEnabled
                                    ? 'transparent'
                                    : 'white',
                              }}
                              onClick={() => {
                                setLoading(true);
                                setFilter(item);
                                setFilterClicked(true);
                              }}
                            >
                              {`${item === FILTERTYPES.SELFPACED ? 'Self-Paced' : item} ${
                                FilteredSessions(sessions, item, isCoach)?.length || 0
                              }`}
                            </FilterTabButton>
                          </>
                        ))}
                      </FilterTabsContainer>
                      <div style={{ minWidth: 'fit-content', display: 'flex' }}>
                        {contribution?.isPurchased && CanClinetInviteFrieds(contribution) && (
                          <>
                            <S.StyledShareButton
                              themedColor={themedColor}
                              style={{ textWrap: 'nowrap', margin: '10px', paddingTop: '20px' }}
                              onClick={() => {
                                setInviteModalOpened(true);
                              }}
                              isDarkModeEnabled={contribution?.isDarkModeEnabled}
                            >
                              <S.StyledShareIcon />
                              Invite Friends
                            </S.StyledShareButton>
                            {InviteModalOpened && (
                              <ShareModalContainer
                                isOpen={InviteModalOpened}
                                onSubmit={handleModalSubmit}
                                onModalClose={handleModalClose}
                                paymentInfo={contribution?.paymentInfo}
                                isInvoiced={contribution?.isInvoiced}
                                contributionId={contribution?.id}
                                symbol={contribution?.defaultSymbol}
                                setEmailsError={setEmailsError}
                                emailsError={emailsError}
                                type={contribution.type}
                                IsModalForClient={!isCoach}
                                inviteClientType={contribution?.inviteClientType}
                              />
                            )}
                          </>
                        )}
                        {isCoach && sessions.filter(s => s.isPrerecorded).length > 0 && (
                          <SelfPacedEngagementDataButton onClick={onShareButtonClick} themedColor={progressbarColor}>
                            Client Self-Paced Engagement Data
                          </SelfPacedEngagementDataButton>
                        )}
                        {isCoach && (
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                            <Switch className="switch">
                              <StyledInput
                                type="checkbox"
                                onClick={() => {
                                  changeCalendarView(contribution.id).then(() => {
                                    dispatch(fetchCohealerContribution(contributionId));
                                  });
                                }}
                                checked={contribution?.isCalendarHidden}
                              />
                              <StyledSlider
                                className="slider round"
                                style={{
                                  backgroundColor:
                                    isDarkModeEnabled && contribution?.isCalendarHidden && colorToUse?.PrimaryColorCode,
                                }}
                              />
                            </Switch>
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: '0.875rem',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                marginLeft: '5px',
                                textWrap: 'nowrap',
                              }}
                            >
                              Show Calendar
                            </p>
                            <Tooltip
                              title="When enabled, your clients gain access to a calendar tab displaying all upcoming sessions. When disabled, this feature remains exclusively visible to you."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                              style={{ marginLeft: '5px' }}
                            >
                              <InfoIcon
                                htmlColor={isDarkModeEnabled ? colorToUse?.PrimaryColorCode : colors.lightBrown}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      {connectStripeModal && (
                        <Modal
                          isOpen={connectStripeModal}
                          onCancel={() => {
                            setConnectStripeModal(false);
                          }}
                          onSubmit={() => {
                            setConnectStripeModal(false);
                          }}
                          hiddenCancel
                          title="Error"
                          submitTitle="OK"
                        >
                          <StyledText {...(isDarkModeEnabled && { textColor: themedColor })}>
                            No Enrollment found for Contribution: {contribution?.title}
                          </StyledText>
                        </Modal>
                      )}
                    </>
                  </TabsRow>
                  <ContentRow
                    className="row--body"
                    {...{
                      mobileView,
                      // heightOffset: heightOffsetMemoized
                      ...(useInternalLeftColumnScroll && { heightOffset: 350 }),
                    }}
                    // ref={columnsContainerRef}
                  >
                    <SessionsTabLayout
                      // isSelectedToView={Boolean(selectedSessionId && selectedSessionTimeOrContentId)}
                      leftCol={
                        // <></>
                        <LeftSidebarContainer
                          {...{
                            // memoizedLeftColHead,
                            percentageCompleted,
                            columnThemeProps,
                            useInternalLeftColumnScroll,
                            handleDragEnd,
                            // memoizedItems,
                            mainBorderColor,
                            filter,
                            sidebarFilteredSessions,
                            sessionToBeExpanded,
                            expandedSessionsMap,
                          }}
                        />
                      }
                      centerCol={
                        // <></>
                        <MainContentColumn
                          head={
                            selectedSessionId &&
                            selectedSessionTimeOrContentId && (
                              <SessionHeader
                                {...{
                                  resetSelectedSession: () => {
                                    batch(() => {
                                      // setSelectedSessionId(null);
                                      // setSelectedSessionTimeOrContentId(null);
                                      dispatch(setShowMobileDetailedView(false));
                                    });
                                  },
                                  // selectNextSession,
                                  selectNextSession: selectNextSession(),
                                  // selectNextSession: debouncedSelectNextSession,
                                }}
                              />
                            )
                          }
                          rightColCollapsed={!openDrawer}
                          {...{
                            ...columnThemeProps,
                            bodyBackgroundColor: contribution?.isDarkModeEnabled ? newThemedBackgroundColor : '#fff',
                            headBorderColor: mainBorderColor,
                            mobileView,
                            isClient,
                          }}
                        >
                          {selectedSessionId && selectedSessionTimeOrContentId && (
                            <SessionBody
                              convertedToSelfPaced={convertedToSelfPaced}
                              index={0}
                              rightColCollapsed={!openDrawer}
                              {...{
                                // selectNextSession,
                                selectNextSession: selectNextSession(),
                                // selectNextSession: debouncedSelectNextSession
                              }}
                            />
                          )}
                        </MainContentColumn>
                      }
                      rightCol={
                        // <></>
                        <RightSidebarColumn collapsed={!openDrawer} {...{ ...columnThemeProps, mobileView }}>
                          {selectedSessionId && <MiniDrawer open={mobileView || openDrawer} setOpen={setOpenDrawer} />}
                        </RightSidebarColumn>
                      }
                    />
                  </ContentRow>
                </StyledMainContainer>
              </ErrorBoundary>
            </>
          }
        </>
      )}
    </>
  );
};

CourseSessions.propTypes = {
  changeShowPopUp: PropTypes.func,
  renderSessionTimeActions: PropTypes.func.isRequired,
};

CourseSessions.defaultProps = {
  changeShowPopUp: () => {},
};

export default memo(CourseSessions, isEqual);
