import React, { useCallback, useState, useRef } from 'react';
import classes from './welcomecohereaffiliate.module.scss';
import Modal from 'components/UI/Modal';
import styled, { css } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { StyledVideo } from 'components/App/GettingStarted/GettingStarted.styles';
import { useTheme, useMediaQuery, Grid } from '@material-ui/core';
import Overlay from 'assets/overlay.png';
import Button from 'components/FormUI/Button';
import ShareIcon from 'assets/ic-share-white.svg';
import InviteCoachesModal from '../InviteCoachesModal/InviteCoaches.Modal';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

const StyledImgCongratsOverlay = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 560px;
  height: 315px;
  ${({ mobileView }) =>
    mobileView &&
    `
  width: 350px;
  height: 315px;
`};
  display: inline-block;
  align-items: center;
  // z-index: 1;
  position: relative;
`;
export default function WelcomeCohereAffiliates({ isOpen, onSubmit, onCancel, ...restProps }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);
  const [showTerms, setShowTerms] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const togglePlay = () => {
    setShowVideo(true);
    setTimeout(() => {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }, [2000]);
  };
  return (
    <Modal isOpen={isOpen} onCancel={onCancel} bodyStyles={{ maxHeight: '85vh' }} {...restProps}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerText}>Welcome to the Cohere Affiliate Program</div>
          <StyledCloseIcon onClick={onCancel} />
        </div>
        <Grid style={{ display: 'flex' }} xs={12} sm={12} lg={6}>
          {showVideo ? (
            <StyledVideo
              mobileView={mobileView}
              ref={videoRef}
              style={{ marginTop: '20px' }}
              controls="controls"
              preload="metadata"
            >
              <source src={`https://coherepublic-prod.s3.amazonaws.com/marketing/cohere+dashboard+w+1to1.mp4`} />
            </StyledVideo>
          ) : (
            <div style={{ marginTop: '20px' }} onClick={togglePlay}>
              <StyledImgCongratsOverlay mobileView={mobileView} previewImg={Overlay}></StyledImgCongratsOverlay>
            </div>
          )}
        </Grid>
        <div className={classes.bottom_container}>
          <Button
            className={classes.welcome_button}
            autoWidth
            onClick={() => {
              setShowInviteModal(true);
            }}
          >
            <Icon src={ShareIcon} />
            Invite Coaches
          </Button>
          <ul className={classes.orderList}>
            <li className={classes.text}>
              Earn a 30% commission on every referral that results in a paid subscription or service purchase.
            </li>
            <li className={classes.text}>
              Share your unique referral link with your audience, clients, or fellow coaches.
            </li>
            <li className={classes.text}>
              Referrals are automatically tracked through your unique link, ensuring you get credited for every
              successful sign-up.
            </li>
            <li className={classes.text}>
              Connecting a Stripe account is optional. If you choose not to connect, we’ll track your earnings and offer
              alternative payout methods.
            </li>
            <li className={classes.text}> Easily monitor your referral performance, earnings, and payout status.</li>
          </ul>
          <div
            onClick={() => {
              setShowTerms(true);
            }}
            className={classes.tnc}
          >
            Terms and Conditions
          </div>
        </div>
      </div>
      <InviteCoachesModal setShowInviteModal={setShowInviteModal} showInviteModal={showInviteModal} />
      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
    </Modal>
  );
}
