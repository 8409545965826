import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Box, TextField } from '@mui/material';
import CustomButton from 'components/FormUI/Button';

const StyledModal = styled(Modal)`
  .cross-icon-hide {
    padding-top: 0px !important;
  }

  .body {
    padding: 0px 16px !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const AddContextTitle = styled.p`
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
  font-style: normal;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 24px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.1899999976158142px;
  text-align: left;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-bottom: auto;
  margin-top: 12px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')}
`;

const ContextContainer = styled.div`
  display: flex;
  width: ${({ mobileView }) => (mobileView ? '350px' : '740px')};
  flex-direction: column;
  align-items: center;
  padding: ${({ mobileView }) => (mobileView ? '10px' : '10px')};
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
`;

const ContextSubtitle = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
  width: 100% !important;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
  width: ${({ mobileView }) => (mobileView ? '350px' : '580px')};
`;

const ContextTextarea = styled(TextField)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;

  & .MuiInputBase-input::placeholder {
    color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '#000000')};
    font-family: Avenir;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
  }

  .MuiInputBase-root {
    padding: 10px;
  }

  & .MuiInputBase-root:hover {
    border-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
  }

  & .MuiInputBase-input {
    color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
    caret-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
    font-family: Avenir;
  }
`;

const CancelButton = styled(CustomButton)`
  height: 48px;
  padding: 7px 24px 7px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  border: ${({ isdarkthemeenabled }) =>
    isdarkthemeenabled ? '1px solid white' : '1px solid var(--cohere-greys-000000, #000)'};
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : '#000')};
  background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  &:hover {
    background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  }
`;

const NextButton = styled(CustomButton)`
  height: 48px;
  padding: 10px 24px 10px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  display: flex;
  padding: 12px 32px;
  align-items: center;
  align-self: stretch;
  background: var(--Cohere-Secondary-Brown, #c9b382);
`;

const FooterContainer = styled(Box)`
  margin: 10px 0px;
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 16px;
`;

const AddContextModal = ({ isOpen, handleClose, handleSubmit, isdarkthemeenabled, mobileView }) => {
  const [text, setText] = useState('');

  const handleTextSubmit = () => {
    handleSubmit(text);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onCancel={handleClose}
      disableFooter
      CrossIconHide
      disableOverlayClick
      isCreatingContribution
    >
      <ContextContainer isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
        <HeaderContainer>
          <AddContextTitle isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
            Add more context for better content
          </AddContextTitle>
          <StyledCloseIcon isDarkThemeEnabled={isdarkthemeenabled} onClick={handleClose} />
        </HeaderContainer>
        <ContextSubtitle isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
          Why are you inspired to create this service and what impact do you want to create for others?
        </ContextSubtitle>
        <ContextTextarea
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="Answer"
          multiline
          rows={6}
          variant="outlined"
          isdarkthemeenabled={isdarkthemeenabled}
          sx={{
            '& .MuiFormHelperText-root': {
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '0.75rem !important',
              fontWeight: 'normal !important',
              fontFamily: 'Avenir',
              lineHeight: '1.5rem',
              letterSpacing: '0.08px',
              marginLeft: '0px',
              marginRight: '0px',
              textAlign: 'end',
            },
          }}
        />
        <FooterContainer mt={2}>
          <CancelButton onClick={handleClose} isdarkthemeenabled={isdarkthemeenabled}>
            Skip
          </CancelButton>
          <NextButton onClick={handleTextSubmit} disabled={!text.trim()} isdarkthemeenabled={isdarkthemeenabled}>
            Next
          </NextButton>
        </FooterContainer>
      </ContextContainer>
    </StyledModal>
  );
};

export default AddContextModal;
