import classes from './ReferalRow.module.scss';
import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: 0,
    paddingRight: 0,
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '350',
  },
})(TableCell);

export default function ReferalRow({ row }) {
  return (
    <TableRow className={classes.container}>
      <NarrowTableCell align="left" component="th" scope="row">
        {row.name === null ? '-' : row.name}
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        {row.status === 'Plan Purchased' ? (
          <div className={classes.plan_purchase}>Plan Purchased</div>
        ) : (
          <div className={classes.no_purchase}>No Purchase</div>
        )}
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        {row.planPurchased === null ? '-' : row.planPurchased}
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        {row.frequency === null ? '-' : row.frequency}
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        ${row.revenue} USD
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        {row.revenueStatus === 'PaidOut' ? (
          <div className={classes.plan_purchase}>Paid Out</div>
        ) : (
          <div className={classes.no_purchase}>Pending</div>
        )}
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        {row?.formattedDateTransferred === null || row?.formattedDateTransferred?.length === 0
          ? '-'
          : row.formattedDateTransferred}
      </NarrowTableCell>
    </TableRow>
  );
}
