import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Box, TextField } from '@mui/material';
import CustomButton from 'components/FormUI/Button';
import Logo from 'components/FormUI/Logo';
import Button from 'components/FormUI/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { getImprovedBioText } from 'services/openAI.service';
import { useAccount } from 'hooks/index';
import { editUserProfile } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../constants';

const StyledModal = styled(Modal)`
  .cross-icon-hide {
    padding-top: 0px !important;
  }

  .body {
    padding: {({ mobileView }) => (mobileView ? '0px 4px !important' : '0px 16px !important')};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const AddBioTitle = styled.p`
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
  font-style: normal;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 24px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.1899999976158142px;
  text-align: left;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-bottom: auto;
  margin-top: 12px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px 10px 10px;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
  padding: ${({ mobileView }) => (mobileView ? '0px 5px 10px 5px' : '0px 10px 10px 10px')};
`;

const Subtitle = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
`;

const FooterContainer = styled(Box)`
  margin: 10px 0px;
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 16px;
`;

const CancelButton = styled(CustomButton)`
  height: 48px;
  padding: 7px 24px 7px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  border: ${({ isdarkthemeenabled }) =>
    isdarkthemeenabled ? '1px solid white' : '1px solid var(--cohere-greys-000000, #000)'};
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : '#000')};
  background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  &:hover {
    background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  }
`;

const NextButton = styled(CustomButton)`
  height: 48px;
  padding: 10px 24px 10px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  display: flex;
  padding: 12px 32px;
  align-items: center;
  align-self: stretch;
  background: var(--Cohere-Secondary-Brown, #c9b382);
`;

const Instructions = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
  margin-bottom: 10px;
  width: 100%;
`;

const ImproveTextActions = styled.div`
  gap: 10px;
  align-items: center;
  display: none;
  bottom: 55px;
  right: 12px;
  z-index: 3;
  position: relative;

  &.expanded {
    .actions-container {
      gap: 10px;
      align-items: center;
      display: none;
      right: 1px;
      border-radius: 4px;
      opacity: 0px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 12px 0px rgba(53, 71, 90, 0.2);
      padding: 0px 2px 0px 10px;
    }
  }
`;

const ImproveTextContainer = styled.div`
  width: 100%;

  .magic-write-button {
    min-width: 128px;
    width: 138px;
    height: 35px;
    min-height: 35px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
    padding: 4px;
    border-radius: 4px;
    opacity: 0px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 12px 0px rgba(53, 71, 90, 0.2);

    &:hover {
      background: rgba(245, 245, 245, 1);
    }

    img {
      height: 24px;
      width: 24px;
      margin-right: 5px;
      margin-bottom: 3px;
    }

    &.expanded {
      box-shadow: unset;
      margin: 2px;
    }
  }

  .disabled-icon {
    opacity: 30%;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    .improve-text-actions {
      display: flex !important;

      div {
        display: flex !important;
      }
    }
  }

  .typing-animation {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #888;
    display: flex;
    align-items: center;
    background: inherit;
    background-color: inherit;
  }

  .typing-dot {
    animation: blink 1.5s infinite step-start;
    margin: 0 2px;
    opacity: 0;
  }

  .typing-dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing-dot:nth-child(2) {
    animation-delay: 0.3s;
  }

  .typing-dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const BioTextarea = styled(TextField)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;

  textarea {
    height: ${({ mobileView }) => (mobileView ? '200px !important' : '')};
  }

  & .MuiInputBase-input::placeholder {
    color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '#000000')};
    font-family: Avenir;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
  }

  .MuiInputBase-root {
    padding: 10px;
  }

  & .MuiInputBase-root:hover {
    border-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
  }

  & .MuiInputBase-input {
    color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
    caret-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#FFFFFF' : '')};
    font-family: Avenir;
  }
`;

const AddBioModal = ({ isOpen, handleClose, handleSubmit, isdarkthemeenabled, mobileView }) => {
  const { user } = useAccount();
  const [bios, setBios] = useState([{ text: '', undoStack: [], redoStack: [] }]);
  const [loading, setLoading] = useState(false);
  const [currentBioIndex, setCurrentBioIndex] = useState(0);
  const dispatch = useDispatch();

  const handleImproveTextClick = async () => {
    const newBio = await getImprovedBioTextAPI();
    if (newBio === false) return;
    const newBios = [...bios.slice(0, currentBioIndex + 1), { text: newBio, undoStack: [], redoStack: [] }];
    setBios(newBios);
    setCurrentBioIndex(newBios.length - 1);
  };

  const handleForward = () => {
    if (currentBioIndex < bios.length - 1) {
      setCurrentBioIndex(currentBioIndex + 1);
    }
  };

  const handleBackward = () => {
    if (currentBioIndex > 0) {
      setCurrentBioIndex(currentBioIndex - 1);
    }
  };

  const handleUndo = () => {
    if (currentBioIndex >= 0 && bios[currentBioIndex].undoStack.length > 0) {
      const currentUndoStack = [...bios[currentBioIndex].undoStack];
      const lastAction = currentUndoStack.pop();
      const newRedoStack = [...bios[currentBioIndex].redoStack, bios[currentBioIndex].text];
      const newBios = [
        ...bios.slice(0, currentBioIndex),
        {
          ...bios[currentBioIndex],
          text: lastAction,
          undoStack: currentUndoStack,
          redoStack: newRedoStack,
        },
        ...bios.slice(currentBioIndex + 1),
      ];
      setBios(newBios);
    }
  };

  const handleRedo = () => {
    if (currentBioIndex >= 0 && bios[currentBioIndex].redoStack.length > 0) {
      const currentRedoStack = [...bios[currentBioIndex].redoStack];
      const lastAction = currentRedoStack.pop();
      const newUndoStack = [...bios[currentBioIndex].undoStack, bios[currentBioIndex].text];
      const newBios = [
        ...bios.slice(0, currentBioIndex),
        {
          ...bios[currentBioIndex],
          text: lastAction,
          redoStack: currentRedoStack,
          undoStack: newUndoStack,
        },
        ...bios.slice(currentBioIndex + 1),
      ];
      setBios(newBios);
    }
  };

  const handleBioChange = event => {
    const newText = event.target.value;
    const currentBio = bios[currentBioIndex];
    const newUndoStack = [...currentBio.undoStack, currentBio.text];
    const newBios = [
      ...bios.slice(0, currentBioIndex),
      { ...currentBio, text: newText, undoStack: newUndoStack, redoStack: [] },
      ...bios.slice(currentBioIndex + 1),
    ];
    setBios(newBios);
  };

  const handleBioSubmit = async () => {
    const updatedUser = {
      ...user,
      bio: bios[currentBioIndex]?.text,
    };
    setLoading(true);
    await editUserProfile(user.id, updatedUser)
      .then(async res => {
        dispatch(updateUser(updatedUser));
        handleSubmit(bios[currentBioIndex]?.text);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getImprovedBioTextAPI = async () => {
    const data = {
      coachBio: bios[currentBioIndex]?.text,
      businessType: user.businessType,
      occupation: user.occupation,
      profession: user.profession,
      businessTagline: user.profilePageViewModel.tagLine,
      businessSubTagline: user.profilePageViewModel.subtagLine,
      bioHeader: user.profilePageViewModel.bioHeader,
      minLimit: 800,
      maxLimit: 1000,
    };

    try {
      setLoading(true);
      const res = await getImprovedBioText(data);
      setLoading(false);
      return res.CoachBio;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onCancel={handleClose}
      disableFooter
      CrossIconHide
      disableOverlayClick
      isCreatingContribution
      mobileView={mobileView}
    >
      <Container isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
        <HeaderContainer>
          <AddBioTitle isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
            Add your profile bio to generate content
          </AddBioTitle>
          <StyledCloseIcon isDarkThemeEnabled={isdarkthemeenabled} onClick={handleClose} />
        </HeaderContainer>
        <Subtitle isdarkthemeenabled={isdarkthemeenabled}>
          Before we provide personalized content can you please write a brief bio, we will also save this in the bio
          page.
        </Subtitle>
        <Subtitle isdarkthemeenabled={isdarkthemeenabled}>
          Don’t worry about making this perfect, a rough outline is all we need.
        </Subtitle>
        <Instructions isdarkthemeenabled={isdarkthemeenabled}>
          <p style={{ fontSize: '16px', fontWeight: 800, marginBottom: '5px' }}>What to include:</p>
          <ul>
            <li>Your professional background</li>
            <li>Areas of expertise</li>
            <li>Coaching style and philosophy</li>
            <li>Any special achievements or certifications</li>
          </ul>
        </Instructions>
        <ImproveTextContainer>
          <BioTextarea
            value={bios[currentBioIndex].text}
            onChange={handleBioChange}
            placeholder="Enter your bio here"
            multiline
            rows={6}
            variant="outlined"
            isdarkthemeenabled={isdarkthemeenabled}
            mobileView={mobileView}
            inputProps={{ maxLength: 1000 }}
            helperText={`${bios[currentBioIndex]?.text?.length}/1000`}
            sx={{
              '& .MuiFormHelperText-root': {
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.75rem !important',
                fontWeight: 'normal !important',
                fontFamily: 'Avenir',
                lineHeight: '1.5rem',
                letterSpacing: '0.08px',
                marginLeft: '0px',
                marginRight: '0px',
                textAlign: 'end',
              },
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'right', position: 'sticky', height: '0px' }}>
            <Tooltip
              className="cursor-pointer text-grey"
              disableHoverListener={bios[0]?.text.length > 100}
              disableClickListener={bios[0]?.text.length > 100}
              title={bios[0]?.text.length < 100 ? 'Provide some context' : ''}
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <ImproveTextActions
                className={
                  bios?.length >= 2 ||
                  bios[currentBioIndex].undoStack?.length > 0 ||
                  bios[currentBioIndex].redoStack?.length > 0
                    ? 'improve-text-actions expanded'
                    : 'improve-text-actions'
                }
              >
                <div className="actions-container">
                  {bios?.length >= 2 && (
                    <>
                      <ArrowBackIosIcon
                        onClick={handleBackward}
                        className={currentBioIndex === 0 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer', width: '14px', height: '14px' }}
                      />
                      <label style={{ marginBottom: '0px' }}>
                        {currentBioIndex + 1}/{bios.length}
                      </label>
                      <ArrowForwardIosIcon
                        onClick={handleForward}
                        className={currentBioIndex === bios.length - 1 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer', width: '14px', height: '14px' }}
                      />
                    </>
                  )}
                  {(bios[currentBioIndex].undoStack?.length > 0 || bios[currentBioIndex].redoStack?.length > 0) && (
                    <>
                      <UndoIcon
                        onClick={handleUndo}
                        className={bios[currentBioIndex].undoStack.length === 0 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer' }}
                      />
                      <RedoIcon
                        onClick={handleRedo}
                        className={bios[currentBioIndex].redoStack.length === 0 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer' }}
                      />
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    className={
                      bios?.length > 1 ||
                      bios[currentBioIndex].undoStack?.length > 0 ||
                      bios[currentBioIndex].redoStack?.length > 0
                        ? 'expanded magic-write-button'
                        : 'magic-write-button'
                    }
                    style={{
                      color: 'black',
                      letterSpacing: '0.5px',
                      opacity: bios[0]?.text.length < 100 ? '50%' : '100%',
                      cursor: bios[0]?.text.length < 100 || loading ? 'default' : 'pointer',
                    }}
                    onClick={handleImproveTextClick}
                    disabled={bios[0]?.text.length < 100 || loading}
                  >
                    {loading ? (
                      <>
                        <div className="typing-animation">
                          <span className="typing-dot">.</span>
                          <span className="typing-dot">.</span>
                          <span className="typing-dot">.</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <Logo isai />
                        Improve Text
                      </>
                    )}
                  </Button>
                </div>
              </ImproveTextActions>
            </Tooltip>
          </div>
        </ImproveTextContainer>
        <FooterContainer mt={2}>
          <CancelButton onClick={handleClose} isdarkthemeenabled={isdarkthemeenabled}>
            Cancel
          </CancelButton>
          <NextButton
            onClick={handleBioSubmit}
            disabled={!bios[0]?.text.trim()}
            isdarkthemeenabled={isdarkthemeenabled}
          >
            Next
          </NextButton>
        </FooterContainer>
      </Container>
    </StyledModal>
  );
};

export default AddBioModal;
