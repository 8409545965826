import React from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Box, TextField } from '@mui/material';
import CustomButton from 'components/FormUI/Button';

const StyledModal = styled(Modal)`
  .cross-icon-hide {
    padding-top: 0px !important;
  }

  .body {
    padding: 0px 16px !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const MagicWriteTitle = styled.p`
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
  font-style: normal;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 24px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.1899999976158142px;
  text-align: left;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-bottom: auto;
  margin-top: 12px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')}
`;

const MagicWriteContainer = styled.div`
  display: flex;
  width: ${({ mobileView }) => (mobileView ? '350px' : '740px')};
  flex-direction: column;
  align-items: center;
  padding: ${({ mobileView }) => (mobileView ? '10px' : '10px')};
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};

  @media (max-width: 815px) and (min-width: 576px) {
    padding: 20px 20px;
  }
`;

const MagicWriteSubtitle = styled.div`
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  width: 100% !important;
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#fff' : '#000')};
  width: ${({ mobileView }) => (mobileView ? '350px' : '580px')};
`;

const JustThisOneButton = styled(CustomButton)`
  height: 48px;
  padding: 7px 24px 7px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  border: ${({ isdarkthemeenabled }) =>
    isdarkthemeenabled ? '1px solid white' : '1px solid var(--cohere-greys-000000, #000)'};
  color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? 'white' : '#000')};
  background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  &:hover {
    background-color: ${({ isdarkthemeenabled }) => (isdarkthemeenabled ? '#333' : 'white')};
  }
`;

const AllFieldsButton = styled(CustomButton)`
  height: 48px;
  padding: 10px 24px 10px 24px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  display: flex;
  padding: 12px 32px;
  align-items: center;
  align-self: stretch;
  background: var(--Cohere-Secondary-Brown, #c9b382);
`;

const FooterContainer = styled(Box)`
  margin: 10px 0px;
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 16px;
`;

const MagicWriteOptionModal = ({ isOpen, handleClose, handleSubmit, isdarkthemeenabled, mobileView }) => {
  const handleJustThisOne = () => {
    handleSubmit('one');
  };

  const handleAllFields = () => {
    handleSubmit('all');
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onCancel={handleClose}
      disableFooter
      CrossIconHide
      disableOverlayClick
      isCreatingContribution
    >
      <MagicWriteContainer isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
        <HeaderContainer mobileView={mobileView}>
          <MagicWriteTitle isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
            Magic write
          </MagicWriteTitle>
          <StyledCloseIcon isDarkThemeEnabled={isdarkthemeenabled} onClick={handleClose} />
        </HeaderContainer>
        <MagicWriteSubtitle isdarkthemeenabled={isdarkthemeenabled} mobileView={mobileView}>
          Do you want to generate content for just this section or all sections? If you choose all sections, any
          existing content will be overwritten.
        </MagicWriteSubtitle>
        <FooterContainer mt={2}>
          <JustThisOneButton onClick={handleJustThisOne} isdarkthemeenabled={isdarkthemeenabled}>
            Just This One
          </JustThisOneButton>
          <AllFieldsButton onClick={handleAllFields} isdarkthemeenabled={isdarkthemeenabled}>
            All Sections
          </AllFieldsButton>
        </FooterContainer>
      </MagicWriteContainer>
    </StyledModal>
  );
};

export default MagicWriteOptionModal;
